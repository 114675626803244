<template>
    <div class="the-type" :class="{ 'selected': chart_type === chart_type_text }" @click="clickType(chart_type_text)">
        <div class="value" v-if="sum === 0">---</div>
        <div class="value" v-else>{{chart_type_text==='sales'?`$`:``}}{{ sum.toLocaleString('en-US') }}
            <svg-icon name="arrow-up" v-if="trend !== 'same'" :class="trend" />
        </div>
        <div class="text-big">{{ value_text }}</div>

        <div class="desc-box" v-if="show_sortlink">
            <div class="desc" >
                <div class="desc-text">From shortlinks</div> 
                <div class="desc-text">{{chart_type_text==='sales'?`$`:``}}{{ sort_value.toLocaleString('en-US') }}</div>
            </div>
            <div class="desc">
                <div class="desc-text">From storefront</div> 
                <div class="desc-text"> {{chart_type_text==='sales'?`$`:``}}{{ store_value.toLocaleString('en-US') }}</div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: 'SummaryValue',

    components: {
        
    },

    data() {
        return {
            
        }
    },

    props: {
        chart_type: {
            type: String,
            default: ''
        },
        chart_type_text:{
            type: String,
            default: ''
        },

        sum:{
            type: Number,
            default: 0
        },

        trend:{
            type: String,
            default: ''
        },

        value_text:{
            type: String,
            default: ''
        },

        show_sortlink:{
            type: Boolean,
            default: true
        },

        sort_value:{
            type: [String , Number],
            default: 0
        },

        store_value:{
            type: [String , Number],
            default: 0
        },
    },

    methods: {
        clickType(){
            this.$emit('clickType', this.chart_type_text )
        }
    },

}
</script>
<style lang="scss">
.the-type {
    width: 25%;
    padding: 16px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    flex-wrap:wrap;
    align-content: flex-start;

    &.selected {
        background: var(--bg-02);
        border: unset;
    }

    .text-big{
        font: var(--font-med-16);
        color: var(--text-secondary);
    }

    .desc-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: var(--black-75);
    }

    .value {
        font:var(--font-med-24);
        color: var(--dark-blue);
        display: flex;
        align-items: center;
        width: 100%;

        svg {
            margin-left: 10px;
            width: 10px;
            height: 10px;
            fill: var(--green);

            &.down {
                fill: var(--red);
                transform: rotate(90deg)
            }
        }
    }


    .desc-box{
        margin-top: 12px;
        width:100%;
        
        .desc{
            margin-top: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            
        }
    }
}
</style>