<template>
    <div class="ui-checkbox" :class="[
            {'checked': checked}, 
            {'disabled': disabled}, 
            {'align-start':align_start},
            size ? 'size-' + size : 'size-medium'
        ]" @click="checkBox">
        <svg-icon v-if="checked" name="checked" />
        <svg-icon v-else name="checkbox" />
        <tippy v-if="!no_show_label" :content="desc? desc: label_text" ref="tippyRef" :duration="[0, 0]">
            <span>{{ label_text }}</span>
            <i class="light" v-if="had_svg" ><svg-icon  name="lightning" /></i>
        </tippy>
        <span v-else>{{ label_text }}</span>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue';
const props = defineProps(['checked', 'disabled', 'label_text','align_start', 'size' ,'desc','no_show_label','had_svg']);

const tippyRef = ref(null);

// Method to hide tippy on scroll
const handleScroll = () => {
    if (tippyRef.value) {
        tippyRef.value.hide();
    }
};

// Adding and removing the scroll event listener
onMounted(() => {
    window.addEventListener('wheel', handleScroll);
});

onBeforeUnmount(() => {
    window.removeEventListener('wheel', handleScroll);
});

</script>

<style lang="scss">

    .ui-checkbox {
        cursor: pointer;
        position: relative;
        display: inline-flex;
        align-items: center;
        color: var(--black-75);
        margin: 16px 0;

        svg {
            fill: var(--black-75);
            height: 16px;
            width: 16px;
            margin-right: 8px;
        }

        &.size-small {
            font-size: 12px;

            svg {
                height: 12px;
                width: 12px;
                margin-right: 4px;
            }
        }

        &.size-large {
            font-size: 16px;

            svg {
                height: 20px;
                width: 20px;
            }
        }

        &.checked svg {
            fill: var(--black-90);
        }

        &.disabled {
            opacity: .5;
            pointer-events: none;
        }

        &.align-start{
            align-items: flex-start;

            svg{
                margin-top: 3px;
            }
        }

        span{
            display: flex;
            align-items: center;
        }
        .light{
            margin-left: 6px;
            margin-right: 6px;
            svg{
                width: 16px;
                height:16px;
                fill:var(--black-60);
            }
        }
    }
</style>