

<template>
    <ModalSide class="product-modal" ref="modal">
        <Scrollbar :key="refrash_key">
            <header>
                <!-- Product Performance -->
                <div class="title">{{ user_info.has ? 'Product Performance by Creator' : 'Product Performance' }}
                    <span v-if="user_info.has">
                        <figure class="avatar">
                            <img v-if="user_info.data.userProfile" :src="user_info.data.userProfile.avatar_full_url"
                                @error="imageError($event)" />
                            <img v-else src="@/assets/images/user.png" />
                        </figure>
                        {{ user_info.data.first_name }} {{ user_info.data.last_name }}
                    </span>
                </div>
                <div class="icon-box">
                    <a class="icon i-1" v-if="product_data" target="_blank" :href="product_data.product_url"
                        v-tippy="{ content: 'Open product source in new tab', delay: [500, 20] }">View Product
                        <SvgIcon name="share" />
                    </a>
                    <div class="icon i-2"
                        v-tippy="{ content: 'Download product report summaries in CSV format', placement: 'right', delay: [500, 20] }"
                        @click="downloadCsv()">
                        <SvgIcon name="download2" />
                    </div>
                    <div class="icon i-3" @click="close">
                        <SvgIcon name="close" />
                    </div>
                </div>
            </header>
            <div class="box" v-if="product_data">
                <div class="product-info-box">
                    <figure class="image-wrap">
                        <img class="product-img" :src="product_data.images.img_400x400" />
                    </figure>
                    <div class="product-info">
                        <div class="product-flex-box">
                            <div>
                                <div class="product-brand" v-html="product_data && product_data.brand_name ? product_data.brand_name:''"></div>
                                <div class="product-name" v-html="product_data.name"></div>
                            </div>
                            <div class="product-btn" @click="goFullReport" v-if="user_info.has">Full Product Report</div>
                        </div>
                        <div class="product-data-box">
                            <div class="product-data">
                                <div class="text">Price</div>
                                <div class="value">${{ product_data.on_sale === 1 ? formatMoney(product_data.sale_price) : formatMoney(product_data.price) }}</div>
                            </div>
                            <div class="product-data">
                                <div class="text">Category</div>
                                <div class="value">{{ product_data.category_name }}</div>
                            </div>
                            <div class="product-data">
                                <div class="text">Availability</div>
                                <div class="value">{{ available_text }}</div>
                            </div>
                            <div class="product-data" v-if="user_info.has">
                                <div class="text">Added Date</div>
                                <div class="value">{{ $filters.momentFormat(product_data.created_at, 'll') }}</div>
                            </div>
                            <div class="product-data" v-if="user_info.has">
                                <div class="text">Late Update</div>
                                <div class="value"> {{ $filters.momentFormat(product_data.updated_at, 'll') }}</div>
                            </div>
                            <!-- <div class="report">Report</div> -->
                        </div>
                    </div>
                </div>
                <!---------------------------- chart ------------------------------------->
                <StorefrontSummary :show_date_picker="true" :user_id="user_id" :date_range="date_range"
                    :product_id="product_id" :api_url="summary_api_url" :title="summary_title"  @update-creator-modal-date="updateDate"
                    @handleSortLink="sortLinkClick" :init_sortlink="sortlink_data"/>
                <!---------------------------- for all ----------------------------------->
                <div v-if="!user_info.has">
                    <div class="report-header">
                        <div class="left">
                            <div class="title">Creators ({{ (data_sum) ? data_sum.row_count : '' }})</div>
                            <FilterDropdown :params="params" :disabled="is_empty_data" @update-filter="updateFilter" />
                        </div>
                        <div class="ui-search">
                            <Search @onenter="search" :disabled="is_empty_data" />
                        </div>
                        <div class="right">
                            <!-- hide in 8882 -->
                            <!-- <DatePicker :disabled="is_empty_data" :date_range="date_range" @update-date="updateDate" /> -->
                            <Dropdown :items="select_pagenum" :disabled="is_empty_data" @update-dropdown="updatePagenum" />
                            <Pagenavi :disabled="is_empty_data" :page_total="page_total" :page_current="page_current"
                                @goto-page="gotoPage" />
                        </div>
                    </div>
                    <div class="report-box" v-if="data_sta && !is_loading_data">
                        <TopBox :data="{
                            title: 'Top Product Views',
                            user: data_sta.top_view_user,
                            icon: 'eye'
                        }" />

                        <TopBox :data="{
                            title: 'Top Clicks',
                            user: data_sta.top_click_sum_user,
                            icon: 'click'
                        }" />

                        <TopBox :data="{
                            title: 'Top Sales',
                            user: data_sta.top_sales_sum_user,
                            icon: 'pig'
                        }" />

                        <TopBox :data="{
                            title: 'Top Sales Value',
                            user: data_sta.top_sales_value,
                            icon: 'shopping-cart'
                        }" />

                    </div>
                    <div>
                        <FilterResults :params="params" @update-filter="updateFilter" />
                    </div>
                    <!-- table -->
                    <Loading class="static" v-if="is_loading" />
                    <Scrollbar class="data-list" v-else>
                        <Table :style="top" :class="{ 'is-loading': is_loading_data }">
                            <template #head>
                                <tr>
                                    <th>
                                        <div class="ico sort"
                                            :class="[{ 'asc': sort_key === 'name' && sort_val === 'asc' }, { 'desc': sort_key === 'name' && sort_val === 'desc' }]"
                                            @click="sort('name')">
                                            <span>Name</span>
                                            <i>
                                                <SvgIcon name="sort" />
                                            </i>
                                        </div>
                                    </th>
                                    <th><span>Socials</span></th>
                                    <th>
                                        <div class="ico sort"
                                            :class="[{ 'asc': sort_key === 'anchor_status' && sort_val === 'asc' }, { 'desc': sort_key === 'anchor_status' && sort_val === 'desc' }]" @click="sort('anchor_status')">
                                            <tippy :delay="[0, 20]"  class="flex"
                                                content="This is the current status of each creator's storefront with the date that status updated. Please hover over the status to learn more.​">
                                                <span>Status</span>
                                                <i>
                                                    <SvgIcon name="sort" />
                                                </i>
                                            </tippy>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="ico sort"
                                            :class="[{ 'asc': sort_key === 'product_views' && sort_val === 'asc' }, { 'desc': sort_key === 'product_views' && sort_val === 'desc' }]"
                                            @click="sort('product_views')">
                                            <tippy :delay="[0, 20]"  class="flex"
                                                content="Cumulative product views for the time period selected.​">
                                                <span>Product Views</span>
                                                <i>
                                                    <SvgIcon name="sort" />
                                                </i>
                                            </tippy>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="ico sort"
                                            :class="[{ 'asc': sort_key === 'click' && sort_val === 'asc' }, { 'desc': sort_key === 'click' && sort_val === 'desc' }]"
                                            @click="sort('click')">
                                            <tippy :delay="[0, 20]"  class="flex" content="Total product link clicks for the time period selected.​">
                                                <span>Clicks</span>
                                                <i>
                                                    <SvgIcon name="sort" />
                                                </i>
                                            </tippy>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="ico sort"
                                            :class="[{ 'asc': sort_key === 'sales' && sort_val === 'asc' }, { 'desc': sort_key === 'sales' && sort_val === 'desc' }]"
                                            @click="sort('sales')">
                                            <tippy :delay="[0, 20]"  class="flex" content="Sales driven by the storefront for the time period selected.​">
                                                <span>Sales</span>
                                                <i>
                                                    <SvgIcon name="sort" />
                                                </i>
                                            </tippy>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="ico sort"
                                            :class="[{ 'asc': sort_key === 'sales_value' && sort_val === 'asc' }, { 'desc': sort_key === 'sales_value' && sort_val === 'desc' }]"
                                            @click="sort('sales_value')">
                                            <tippy :delay="[0, 20]"  class="flex"
                                                content="Gross transaction value of sales driven by storefront in the time period selected.​">
                                                <span>Sales Value</span>
                                                <i>
                                                    <SvgIcon name="sort" />
                                                </i>
                                            </tippy>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="ico sort"
                                            :class="[{ 'asc': sort_key === 'add_date' && sort_val === 'asc' }, { 'desc': sort_key === 'add_date' && sort_val === 'desc' }]"
                                            @click="sort('add_date')">
                                            <tippy :delay="[0, 20]"  class="flex"
                                                content="Date this product was added to the creator's storefront.">
                                                <span>Added</span>
                                                <i>
                                                    <SvgIcon name="sort" />
                                                </i>
                                            </tippy>
                                        </div>
                                    </th>
                                    <th>
                                        <div class="ico question sort"
                                            :class="[{ 'asc': sort_key === 'last_update_date' && sort_val === 'asc' }, { 'desc': sort_key === 'last_update_date' && sort_val === 'desc' }]">
                                            <tippy :delay="[0, 20]"  content="This date will change when a creator adds or edits products on their storefront."
                                                class="flex question">
                                                <span @click="sort('last_update_date')">Last Update</span>
                                                <i @click="sort('last_update_date')">
                                                    <SvgIcon name="sort" />
                                                </i>
                                            </tippy>
                                        </div>
                                    </th>
                                </tr>
                            </template>
                            <template #body>
                                <template v-if="!is_loading && data.length === 0">
                                    <tr>
                                        <td colspan="10">
                                            <NoData />
                                        </td>
                                    </tr>
                                </template>
                                <tr v-else v-for="(row, index) in data" :key="index"
                                    :class="['tbody-tr']" @click.stop="pickUser(row)">

                                    <td class="name">
                                        <div class="profile">
                                            <img v-if="row.userProfile" :src="row.userProfile.avatar_full_url"
                                                @error="imageError($event)" />
                                            <img v-else src="@/assets/images/user.png" />
                                            

                                            <div class="name-text-box"> 
                                                <tippy :content="showFullText(row, 'name')" 
                                                @mouseover="isEllipsisActive($event, row)" class="name">
                                                <strong class="text-ellipsis"
                                                    v-html="row.first_name + ' ' + row.last_name"></strong>
                                                </tippy>
                                                <div class="email-box" v-if="$store.state.user_data.is_motom_internal_user">
                                                    <svg-icon name="email" />
                                                    <tippy :content="showFullText(row, 'email')" @mouseover="isEllipsisActive($event, row)"  >
                                                        <span v-if="row.email" class="link" >
                                                        <span class="text-ellipsis" @click.stop="emailto(row.email)">{{ row.email }}</span></span>
                                                        <!-- <a :href="`mailto:${row.email}`"><span class="text-ellipsis">{{ row.email }}</span></a></span> -->
                                                        <span class="link" v-else> ---</span><br>
                                                    </tippy>
                                                </div>
                                            </div>
                                        </div>

                                    </td>


                                    <td class="social">
                                        <tippy :content="showFullText(row, 'tt')"
                                            @mouseover="isEllipsisActive($event, row)" @click.stop="GoMedia('tk',row.tiktok_handle)">
                                            <span v-if="row.tiktok_handle" class="text-ellipsis link text-ellipsis-link">
                                                <SvgIcon name="tiktok-pure" /> {{ row.tiktok_handle }} <b v-if="row.is_tiktok_handle_updated">Updated</b>
                                            </span>
                                            <span v-else>
                                                <SvgIcon name="tiktok-pure" /> ---
                                            </span><br>
                                        </tippy>
                                        <tippy :content="showFullText(row, 'ins')"
                                            @mouseover="isEllipsisActive($event, row)" @click.stop="GoMedia('ig',row.instagram_handle)">
                                            <span v-if="row.instagram_handle" class="text-ellipsis link text-ellipsis-link">
                                                <SvgIcon name="instagram" /> @{{ row.instagram_handle }}  <b v-if="row.is_instagram_handle_updated">Updated</b>
                                            </span>
                                            <span v-else>
                                                <SvgIcon name="instagram" /> ---
                                            </span>
                                        </tippy>
                                    </td>

                                    <td>
                                        <tippy :delay="[0, 20]"  class="storefront-state" :content="showStageText(row.anchor_page_status , $filters.momentFormat(row.show_status_time, 'll'))"
                                            :class="['class-' + row.anchor_page_status]">
                                            {{ row.anchor_page_status_text }}
                                        </tippy>
                                        <p class="status_text_time">{{$filters.momentFormat(row.show_status_time, 'll') }}</p>
                                    </td>
                                    <!-- <td class="social">
                                    <a v-if="row.instagram_handle" href="https://instagram.com" target="_blank"><SvgIcon name="instagram" /> @{{ row.instagram_handle }}</a>
                                    <span v-else><SvgIcon name="instagram" /> ---</span>
                                    <br>
                                    <a v-if="row.tiktok_handle" href="https://www.tiktok.com/" target="_blank"><SvgIcon name="tiktok-pure" /> {{ row.tiktok_handle }}</a>
                                    <span v-else><SvgIcon name="tiktok-pure" /> ---</span>
                                </td>
                                <td>
                                    <span class="storefront-state" :class="row.anchor_page_status">{{ row.anchor_page_status_text }}</span>
                                </td> -->
                                    <td>
                                        <span v-if="!isNaN(row.product_views)">{{ row.product_views }}</span>
                                        <span v-else>---</span>
                                    </td>
                                    <td>
                                        <div class="td-flex">
                                            <span v-if="!isNaN(row.click)">{{ row.click }}</span>
                                            <span v-else>---</span>
                                            <i class="lig" v-if="$store.state.save_sortlink_data && row.click_include_shortlink_data"><svg-icon  name="lightning" /></i>
                                            <!-- <tippy v-if="$store.state.save_sortlink_data && row.click_include_shortlink_data"
                                                :delay="[0, 20]" class="flex"
                                                content="Total product link clicks driven by the creator during the time period selected. ">
                                                <i class="lig"><svg-icon  name="lightning" /></i>
                                            </tippy> -->
                                        </div>
                                    </td>
                                    <td>
                                        <div class="td-flex">
                                            <span v-if="!isNaN(row.sales)">{{ row.sales }}</span>
                                            <span v-else>---</span>
                                            <i class="lig" v-if="$store.state.save_sortlink_data && row.sales_include_shortlink_data"><svg-icon  name="lightning" /></i>
                                            <!-- <tippy v-if="$store.state.save_sortlink_data && row.sales_include_shortlink_data"
                                                :delay="[0, 20]" class="flex"
                                                content="Total sales driven by the creator during the time period selected.">
                                                <i class="lig"><svg-icon  name="lightning" /></i>
                                            </tippy> -->
                                        </div>
                                    </td>
                                    <td>
                                        <div class="td-flex">
                                            <span v-if="!isNaN(row.sales_value)">${{ Number(row.sales_value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</span>
                                            <span v-else>---</span>
                                            <i class="lig" v-if="$store.state.save_sortlink_data && row.sale_value_include_shortlink_data"><svg-icon  name="lightning" /></i>
                                            <!-- <tippy v-if="$store.state.save_sortlink_data && row.sale_value_include_shortlink_data"
                                                :delay="[0, 20]" class="flex"
                                                content="Total transaction value of sales driven by the creator during the time period selected.">
                                                <i class="lig"><svg-icon  name="lightning" /></i>
                                            </tippy> -->
                                        </div>
                                    </td>
                                    <td>
                                        {{ $filters.momentFormat(row.store_front_created_at, 'll') }}
                                    </td>
                                    <td>
                                        {{ $filters.momentFormat(row.last_store_front_update_at, 'll') }}
                                    </td>
                                    <!-- <td>
                                    <span>{{ $filters.momentFormat(row.joined_at, 'll') }}</span>
                                </td>
                                <td>
                                    <span>---</span>
                                </td> -->
                                </tr>
                            </template>
                            <template #foot
                                v-if="data_sum && data_sum.row_count > 0 && search_keyword === '' && !has_filters">
                                <tr>
                                    <td colspan="2">Total {{ data_sum.row_count }}</td>
                                    <!-- <td class="social">
                                    <span><SvgIcon name="instagram" /> {{ data_sum.instagram_handle_count }}</span>
                                    <span><SvgIcon name="tiktok-pure" /> {{ data_sum.tiktok_handle_count }}</span>
                                </td> -->
                                    <td>
                                        {{ data_sum.anchor_active_count }} Active <br>
                                        {{ data_sum.anchor_disabled_count }} Disabled
                                    </td>
                                    <td>{{ formatNumber(data_sum.product_view_total) }}</td>
                                    <td>{{ formatNumber(data_sum.clicks_total) }}</td>
                                    <td>{{ formatNumber(data_sum.sales_total) }}</td>
                                    <td>${{ Number(data_sum.sales_value_total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</td>
                                    <td colspan="2"></td>
                                </tr>
                            </template>
                        </Table>
                    </Scrollbar>
                </div>
            </div>
        </Scrollbar>
    </ModalSide>
</template>
<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Loading from '@/components/ui/Loading.vue';
import Table from '@/components/ui/Table.vue';
import ModalSide from '@/components/ui/ModalSide.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import NoData from '@/components/creator/NoData.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import Search from '@/components/ui/Search.vue';
import Dropdown from '@/components/ui/Dropdown.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';
import FilterResults from '@/components/FilterResults.vue';
import Pagenavi from '@/components/ui/Pagenavi.vue';
import formatMoney from '@/utils/formatMoney';
import moment from 'moment';
import TopBox from '@/components/product/TopBox.vue';
import StorefrontSummary from '@/components/summary/StorefrontSummary.vue';
import { goMedia } from '@/utils/goMedia';
import { showStageText , makeStatusTime }from '@/utils/ShareEvent';
import Checkbox from '@/components/ui/Checkbox.vue';

export default {
    name: 'ProductModalSide',

    components: {
        SvgIcon,
        ModalSide,
        Loading,
        Table,
        Scrollbar,
        NoData,
        DatePicker,
        Search,
        Dropdown,
        FilterDropdown,
        FilterResults,
        Pagenavi,
        TopBox,
        StorefrontSummary,
        Checkbox,
    },

    data() {
        return {
            sort_key: 'product_views',
            sort_val: 'desc',
            start_date: '',
            end_date: '',
            product_id: '',
            date_range: {},
            date_range_summary:{},//not use in 8882
            // product: null,
            product_data: null,
            data: [],
            data_sta: null,
            data_sum: null,
            top: '--top: 0px',
            is_loading: true,
            is_loading_data: false,
            search_keyword: '',
            top_views: false,
            top_sales: false,
            top_clicks: false,
            top_sales_value: false,
            total_views: 0,
            total_clicks: 0,
            total_sales: 0,
            total_sales_value: 0,
            select_pagenum: [
                { id: 50, text: '50/page' },
                { id: 20, text: '20/page' },
            ],
            page_size: 0,
            page_total: 0,
            page_current: 1,
            is_empty_data: false,
            params: {
                profile_status: 0,
                storefront_status: 0,
                recently_added: 0,
                metric: 0,
                metric_type: 0,
                metric_amount: '',
                metric_min_amount: '',
                metric_max_amount: '',
                social: {
                    metric: 1,
                    metric_msg: '',
                    metric_min_amount_input: '',
                    metric_max_amount_input: ''
                }
            },
            has_filters: false,
            user_info: {
                has: false,
                data: null
            },
            refrash_key: 0,
            sortlink_data:0,
        }
    },

    computed: {
        available_text() {
            if (this.product_data.src_type == 2)
                return 'Unknown'
            else if (this.product_data.availability === 1)
                return 'In stock'
            else
                return 'Not in stock'
        },

        user_id() {
            if (this.user_info.has && this.user_info.data)
                return this.user_info.data.id
            else
                return '';
        },

        summary_api_url() {
            if (this.user_info.has)
                return '/creator/creator-product-detail-trend-detail'
            else
                return '/analytics/product-detail-trend-detail'
        },

        summary_title() {
            return {
                title: 'Product Summary',
                view: 'Product Views',
                click: 'Clicks',
                sale: 'Sales',
                sale_value: 'Sales Value'
            }
        }
    },

    methods: {
        GoMedia:goMedia,

        sortLinkClick(val){
            this.sortlink_data=val;
            this.statisticData();
            this.getTable();
            this.getSum();
        },

        showFullText(row, type) {
            let text = '';
            if (row.is_ellipsis_active) {
                // return row.last_name + ' ' + row.last_name;
                switch (type) {
                    case 'name':
                        text = row.last_name + ' ' + row.last_name;
                        break;
                    case 'ins':
                        text = row.instagram_handle;
                        if (row.is_instagram_handle_updated) text += ' (Updated)';
                        break;
                    case 'tt':
                        text = row.tiktok_handle;
                        if (row.is_tiktok_handle_updated) text += ' (Updated)';
                        break;
                    case 'email':
                        text = row.email;
                        break;
                }
            }

            return text;
        },

        isEllipsisActive(e, row) {
            row.is_ellipsis_active = (e.target.offsetWidth < e.target.scrollWidth);
        },

        showStageText:showStageText,

        formatNumber(num) {
            return num?num.toLocaleString('en-US'):'---';
        },

        formatMoney: formatMoney,

        imageError(event) {
            event.target.src = require('@/assets/images/user.png');
        },

        open() {
            this.is_loading = true;
            this.$store.state.product_data_open=true;
            this.getInfo();
            this.statisticData();
            this.getTable();
            this.getSum();
            this.$refs.modal.open();
        },

        close() {
            this.$store.state.product_data_open=false;
            this.$refs.modal.close();
        },

        getInfo() {
            let api = `/creator/get-product-detail?product_id=${this.product_id}&start_date=${this.start_date}&end_date=${this.end_date}`;

            if (this.user_info.data && this.user_info.data.id) api = `${api}&creator_user_id=${this.user_info.data.id}`;
            this.$ajax(api, {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                this.product_data = res.data;
            });
        },

        updateDate(obj) {
            this.start_date = moment(obj.start_date).format('YYYY-MM-DD');
            this.end_date = moment(obj.end_date).format('YYYY-MM-DD');
            this.date_range.start_date =this.start_date;
            this.date_range.end_date = this.end_date;
            this.statisticData();
            this.getTable();
            this.getSum();
        },

        updatePagenum(obj) {
            this.page_size = obj.id;
            this.getTable();
        },

        updateFilter(obj) {
            this.params = obj;
            this.getTable();
            this.getSum();
        },

        sort(key) {
            if (key === this.sort_key) {
                this.sort_val = this.sort_val === 'asc' ? 'desc' : 'asc';
            } else {
                this.sort_key = key;
                this.sort_val = 'desc';
            }

            this.getTable();
            this.getSum();
        },

        search(keyword) {
            this.sort_key = '';
            this.sort_val = 'desc';
            this.search_keyword = keyword;
            this.getTable();
            this.getSum();
        },

        gotoPage(page) {
            this.page_current = page;
            this.getTable();
        },

        statisticData() {
            this.data_sta=null
            let api = `/analytics/product-detail-statistic?product_id=${this.product_id}&start_date=${this.start_date}&end_date=${this.end_date}&is_include_shortlink_data=${this.sortlink_data}`;

            this.$ajax(api, {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                this.data_sta = res.data;
            });
        },

        getApiParamStr(api) {
            if (this.search_keyword)
                api = `${api}&keyword=${this.search_keyword}`;

            api = `${api}&per_page=${this.page_size}&page=${this.page_current}&start_date=${this.start_date}&end_date=${this.end_date}`;

            let filters = [];
            if (this.params.profile_status === 1) filters.push({ "field_name": "creator_status", "value": "joined" });
            if (this.params.profile_status === 2) filters.push({ "field_name": "creator_status", "value": "pending" });
            if (this.params.profile_status === 3) filters.push({ "field_name": "creator_status", "value": "deleted" });
            if (this.params.storefront_status === 1) filters.push({ "field_name": "anchor_status", "value": "active" });
            if (this.params.storefront_status === 2) filters.push({ "field_name": "anchor_status", "value": "pending" });
            if (this.params.storefront_status === 3) filters.push({ "field_name": "anchor_status", "value": "disabled" });
            if (this.params.storefront_status === 4) filters.push({ "field_name": "anchor_status", "value": "declined" });
            if (this.params.storefront_status === 5) filters.push({ "field_name": "anchor_status", "value": "invited" });
            if (this.params.recently_added === 1) filters.push({ "field_name": "recently_added", "value": "recently_added" });
            if (this.params.metric_amount !== '' || (this.params.metric_min_amount !== '' && this.params.metric_max_amount !== '')) {
                let metric_txt = '';
                switch (this.params.metric) {
                    case 0:
                        metric_txt = 'product_views';
                        break;
                    case 3:
                        metric_txt = 'click';
                        break;

                    case 2:
                        metric_txt = 'sales';
                        break;

                    case 1:
                        metric_txt = 'sales_value';
                        break;

                    default:
                        break;
                }
                if (this.params.metric_type === 0)
                    filters.push({ "field_name": metric_txt, "operator": '>', "value1": this.params.metric_amount });
                else if (this.params.metric_type === 1)
                    filters.push({ "field_name": metric_txt, "operator": '<', "value1": this.params.metric_amount });
                else
                    filters.push({ "field_name": metric_txt, "value1": this.params.metric_min_amount, "value2": this.params.metric_max_amount });
            }

            //new filter
            if (this.params.social.metric_max_amount_input && this.params.social.metric_min_amount_input) {
                let whi = this.params.social.metric === 1 ? 'follower_filter_tikTok' : 'follower_filter_instagram'
                filters.push({ "field_name": whi, "value1": this.params.social.metric_min_amount_input, "value2": this.params.social.metric_max_amount_input });
            }

            if (filters.length > 0) {
                this.has_filters = true;
                api = `${api}&filters=${JSON.stringify(filters)}`;
            }
            else
                this.has_filters = false;

            return api;
        },

        getTable() {
            if (!this.is_loading) this.is_loading_data = true;
            let api = `/analytics/product-performance-by-creators?product_id=${this.product_id}&is_include_shortlink_data=${this.sortlink_data}`;

            if (this.sort_key)
                api = `${api}&sort_key=${this.sort_key}&sort_val=${this.sort_val}`;

            api = this.getApiParamStr(api);

            this.$ajax(api, {
                method: 'get',
                withCredentials: true,
                data: {}
            }).then(res => {
                this.data = res.data.data.data;
                this.data = this.data.map(e=>{
                    let show_status_time='';
                    switch (e.anchor_page_status) {
                        case 100:
                            show_status_time=e.accept_store_front_datetime;
                            break;
                        case 0:
                            show_status_time=e.disabled_at;
                            break;
                        case -10:
                            show_status_time=e.declined_at;
                            break;
                        case 10:
                            show_status_time=e.unavailable_at;
                            break;
                        case 20:
                            show_status_time=e.created_at;
                            break;
                    }

                    return{
                        ...e,
                        show_status_time:show_status_time
                    }
                });
                this.page_total = res.data.data.last_page;
                this.page_current = res.data.data.current_page;
                this.total_views = res.data.total_views;
                this.total_clicks = res.data.total_clicks;
                this.total_sales = res.data.total_sales;
                this.total_sales_value = res.data.total_sales_value;
                let c_data = JSON.parse(JSON.stringify(this.data));
                if (c_data.length !== 0) {
                    this.top_views = c_data.sort((a, b) => { return b.views - a.views })[0];
                    this.top_clicks = c_data.sort((a, b) => { return b.clicks - a.clicks })[0];
                    this.top_sales = c_data.sort((a, b) => { return b.sales - a.sales })[0];
                    this.top_sales_value = c_data.sort((a, b) => { return b.sales_value - a.sales_value })[0];
                }
                else {
                    this.top_views = null;
                    this.top_clicks = null;
                    this.top_sales = null;
                    this.top_sales_value = null;
                }

                this.is_loading = false;
                this.is_loading_data = false;
                let has_params = this.params.profile_status !== 0 || this.params.storefront_status !== 0 || this.params.recently_added !== 0 ||
                    (((this.params.metric_type === 0 || this.params.metric_type === 1) && this.params.metric_amount !== '') ||
                        (this.params.metric_type === 2 && this.params.metric_min_amount !== '' && this.params.metric_max_amount !== ''));
                this.is_empty_data = !this.is_loading && this.data.length === 0 && this.search_keyword === '' && !has_params;
            });
        },

        getSum() {
            let api = `/analytics/product-performance-by-creators-sum?product_id=${this.product_id}&is_include_shortlink_data=${this.sortlink_data}`;

            api = this.getApiParamStr(api);

            this.$ajax(api, {
                method: 'get',
                withCredentials: true,
            }).then(res => {
                this.data_sum = res.data.data;
            });
        },

        downloadCsv() {
            let api_url = `/analytics/get-product-performance-by-creators-csv-file?product_id=${this.product_id}`;
            let api = this.getApiParamStr(api_url);
            if (this.sort_key) api = `${api}&sort_key=${this.sort_key}&sort_val=${this.sort_val}`;
            if (this.search_keyword) api = `${api}&keyword=${this.search_keyword}`;

            this.$ajax(api, {
                method: 'get',
            }).then(res => {
                if (res.status == 200) {
                    let name = res.headers['content-disposition'].split('filename=');
                    name = name[1] ? name[1] : 'report';
                    this.$JsDownload(res.data, `${name}.csv`);
                    this.$notify(`<svg><use xlink:href="#yes--sprite"></use></svg> Download Success!`);
                } else {
                    this.$notify(`<svg><use xlink:href="#yes--sprite"></use></svg> Download False!`);
                }
            });

        },

        updateModalDate(obj){
            // this.date_range_summary.start_date = moment(obj.start_date).format('YYYY-MM-DD');
            // this.date_range_summary.end_date = moment(obj.end_date).format('YYYY-MM-DD');
        },

        goFullReport() {
            this.user_info.has = false;
            this.user_info.data = null;
            this.refrash_key += 1;
        },

        pickUser(data) {
            this.user_info.has = true;
            this.user_info.data = data;
            this.date_range_summary=JSON.parse(JSON.stringify(this.date_range));
            this.refrash_key += 1;
        },

        emailto(email) {
            window.open(`mailto:${email}`);
        },
    },

    mounted() {
       
        this.EventBus.off('open-product-modal');
        this.EventBus.on('open-product-modal', (obj) => {
            this.sortlink_data=obj.init_sortlink;
            if (this.page_size === 0) this.page_size = this.select_pagenum[0].id;
            this.product_id = obj.product_id;
            this.start_date = moment(obj.start_date).format('YYYY-MM-DD');
            this.end_date = moment(obj.end_date).format('YYYY-MM-DD');
            this.date_range = { "start_date": obj.start_date, "end_date": obj.end_date };
            this.date_range_summary= { "start_date": obj.start_date, "end_date": obj.end_date };

            this.data = [];
            this.product = obj;
            if (obj.data_info) {
                this.user_info.has = true;
                this.user_info.data = obj.data_info;
            } else {
                this.user_info.has = false;
                this.user_info.data = null;
            }
            this.open();
        });
    },
}
</script>
<style lang="scss">
.product-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;

    header {

        .title {
            display: flex;
            align-items: center;

            span {
                border-left: solid 1px var(--black-20);
                padding: 0 6px;
                margin-left: 6px;
                display: flex;
                align-items: center;
            }
        }

        .avatar {
            padding-right: 10px;
            height: 32px;

            img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                border: 1px solid var(--black-40);
            }
        }

    }

    .storefront-summary {
        margin-top: 16px;
    }

    .ui-modal-side--container {
        height: 100%;
        overflow: hidden;
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 100%;

        .box {
            overflow: hidden;
            display: grid;
            grid-template-rows: auto auto auto auto 1fr;
            grid-template-columns: 100%;
        }

        .product-info-box {
            margin-top: 16px;
            padding: 16px;
            display: flex;
            background: var(--bg-02);

            .product-flex-box {
                display: flex;
                align-items: center;
                justify-content: space-between;


                .product-btn {
                    border-radius: 100px;
                    border: solid 1px var(--dark-blue);
                    background-color: #fff;
                    padding: 6px 12px;
                    font-size: 12px;
                    font-weight: 600;
                    color: var(--dark-blue);
                    cursor: pointer;
                    min-width: 124px;
                    max-height: 31.14px;
                    margin-left:10px;
                    display: flex;
                    justify-content: center;
                }
            }

            .image-wrap {
                width: 100px;
                height: 100px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                border-radius: 8px;
                border: 1px solid rgba(224, 224, 224, 1);

                img {
                    display: block;
                    position: absolute;
                    left: 0%;
                    top: 0%;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .product-info {
                margin-left: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1;

                .product-brand {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    color: var(--black-90);
                }

                .product-name {
                    font: var(--font-demi-18);
                    color: var(--black-90);
                }

                .product-data-box {
                    margin-top: 16px;
                    display: flex;

                    .product-data {
                        width: 20%;

                        .text {
                            font: var(--font-fg-12);
                            color: var(--black-60);
                        }

                        .value {
                            font: var(--font-demi-12);
                            color: var(--black-90);
                        }
                    }

                    .report {
                        flex: 1;
                        display: flex;
                        justify-content: right;
                        align-items: flex-end;
                        font: var(--font-fg-12);
                        color: var(--blue);
                        cursor: pointer;
                    }
                }
            }


        }

        .report-header {
            display: flex;
            align-items: center;
            padding: 16px 0;

            .ui-search {
                flex: 1;
                padding: 0 10px;

                .ui-search-input .input {
                    max-width: unset;
                }
            }

            .left {
                display: flex;
                align-items: center;

                .title {
                    font: var(--font-demi-20);
                    color: var(--black-90);
                }

                .ui-button {
                    display: inline-flex;
                    align-items: center;

                    .svg-icon--plus {
                        height: 10px;
                        width: 10px;
                        margin-right: 5px;
                    }
                }

                .ui-filter-dropdown {
                    margin-left: 10px;
                }
            }

            .right {
                display: flex;
                align-items: center;

                .ui-date-picker {
                    margin-right: 10px;
                }

                .ui-dropdown {
                    margin-right: 10px;
                }

                .download-btn {
                    color: var(--black-90);
                    height: 32px;
                    width: 32px;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        height: 16px;
                        width: 16px;
                        display: block;
                    }
                }
            }

            .ui-dropdown {
                .ui-button {
                    border-color: var(--black-10);
                    background-color: var(--black-10);
                }
            }
        }

        .report-box {
            margin: 0 -8px 16px -8px;
            display: flex;

            &+div>.filter-results {
                margin: 0 0 16px;
            }


            .report-item-2 {
                width: calc(25% - 16px);
                background: white;
                border: 1px solid rgba(0, 0, 0, 0.15);
                border-radius: 8px;
                margin: 0 8px;
                position: relative;

                .top {
                    padding: 12px 10px;
                    display: flex;
                    align-items: center;

                    .value {
                        font-size: 14px;
                        color: var(--dark-blue);
                        display: flex;
                        align-items: center;
                    }

                    .name {
                        font-size: 10px;
                        color: var(--black-90);
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;

                        span {
                            color: var(--black-50);
                            padding-left: 4px;
                        }
                    }


                    svg {
                        width: 16px;
                        height: 16px;
                        fill: var(--pink);
                        margin-right: 5px;
                    }
                }


                .avatar {
                    height: 32px;
                    padding-right: 10px;

                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        border: 1px solid var(--black-40);
                    }
                }

                .info {
                    padding: 12px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

                    .text {
                        font: var(--font-fg-12);
                        color: var(--blue);
                        display: flex;

                        svg {
                            width: 14px;
                            height: 14px;
                            fill: var(--blue);
                            margin-right: 4px;
                        }
                    }

                }

            }

        }
    }

    .data-list {
        height: initial;
        padding-bottom: 20px;
    }

    .ui-table {
        thead tr {
            border-top: 1px solid var(--black-20);
        }

        .tbody-tr{
            cursor:pointer;
        }

        .text-ellipsis-link:hover{
            text-decoration: underline;
        }

        td p {
            font-size: 12px;
        }

        td .profile span {
            color: var(--black-90);
        }

        .tfoot td:nth-child(1) {
            text-align: center;
        }

    }

}


</style>