<template>
    <aside class="ui-product-list">
        <div class="list-header">
            <!-- <div class="title">Best performing (250)</div> -->
            <div class="title">{{ title }}
                <span v-if="last_update">Last Update: {{ last_update }}</span>
            </div>
            <div v-if="is_swiper" class="col">
                <span class="arrow swiper-button-prev">
                    <svg-icon name="arrow" />
                </span>
                <span class="arrow swiper-button-next">
                    <svg-icon name="arrow" />
                </span>
            </div>
            <div class="btn-box" v-else>
                <!-- 在analytics的時候要把他跟header的last_update位子互調(9389) -->
                <Checkbox v-if="can_show_checkbox && !show_last_datetime" size="small" :key="'all'" :label_text="'Include Shortlink Data'" :checked="sortlink_data"
                    @click="sortLinkClick" 
                    :desc="`This only appears on products that actually have shortlink data associated.  If someone unchecks the box - this should go away and the numbers adjust accordingly. `"
                    :had_svg="true"/>
                <div class="update-time" v-if="can_show_checkbox && show_last_datetime">Last Update:  {{$store.state.user_data.merchant_data_update_hour_ago}}h ago</div>
                <Dropdown  v-if="!is_loading" :items="sort_select" :direction_type="'right'" :icon="'filter'" :default_value="sort_index" @update-dropdown="updateSort" />
                <DatePicker v-if="show_date_picker && !is_loading" :date_range="date_range" @update-date="updateDate" />
            </div>
        </div>

        <!-- add in 8882 -->
        <div class="short-link" v-if="data_statistic && show_data_statistic" >
            <div class="report-box">
                <div class="report-item" v-if="sortlink_data">
                    <div class="value" v-if="data_statistic.short_link_count === 0">---</div>
                    <div class="value" v-else-if="data_statistic.short_link_count">{{
                        data_statistic.short_link_count.toLocaleString('en-US') }}
                        <SvgIcon name="arrow-up" v-if="data_statistic.short_link_count_trend !== 'same'"
                            :class="data_statistic.short_link_count" />
                    </div>
                    <div class="text">Shortlinks</div>
                </div>
                
                <div class="report-item">
                    <div class="value" v-if="data_statistic.page_views_sum === 0">---</div>
                    <div class="value" v-else-if="data_statistic.page_views_sum">{{
                    data_statistic.page_views_sum.toLocaleString('en-US') }}
                        <SvgIcon name="arrow-up" v-if="data_statistic.page_views_trend !== 'same'"
                            :class="data_statistic.page_views_trend" />
                    </div>
                    <div class="text">Page Views</div>
                </div>

                <div class="report-item">
                    <SValue :chart_type_text="'click'" 
                        :sum="plusData.all_click" :trend="data_statistic.click_trend" :value_text="'Clicks'"
                        :show_sortlink="sortlink_data && plusData.can_toggle_sortlink" :sort_value="data_statistic.short_link_click_sum" :store_value="data_statistic.click_sum"
                    />
                </div>

                <div class="report-item">
                    <SValue :chart_type="chart_type" :chart_type_text="'tran'" 
                        :sum="plusData.all_sales" :trend="data_statistic.sales_trend" :value_text="'Sales'"
                        :show_sortlink="sortlink_data && plusData.can_toggle_sortlink" :sort_value="data_statistic.short_link_sales_sum" :store_value="data_statistic.sales_sum"
                    />
                </div>
        
                <div class="report-item">
                    <SValue :chart_type_text="'sales'" 
                        :sum="Math.ceil(plusData.all_sales_value)" :trend="data_statistic.sales_value_trend" :value_text="'Sales Value'"
                        :show_sortlink="sortlink_data && plusData.can_toggle_sortlink" :sort_value="Math.ceil(data_statistic.short_link_sales_value_sum)" :store_value="Math.ceil(data_statistic.sales_value_sum)"
                    />
                </div>
            </div>
        </div>

        <swiper v-if="is_swiper" :modules="modules" :slides-per-view="'auto'" :slides-per-group="3" :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }">

            <swiper-slide v-for="(item, index)  in data" :key="'product-' + item.product.id">
                <ProductItem @click="open(item)" :data="item" :show_data_link="plusData.can_toggle_sortlink && sortlink_data"/>
            </swiper-slide>
        </swiper>

        <div :class="['list', is_loading && 'list-loading']" v-else-if="data.length !== 0">
            <Loading v-if="is_loading && !is_loading_page" class="static"/>
            <!-- if is_loading_page=true,mean need to loading in bottom, product need to show -->
            <ProductItem v-for="(item, index)  in data" @click="open(item)" :data="item"
                :key="'product-' + item.product.id" v-show="!is_loading || is_loading_page" :show_data_link="plusData.can_toggle_sortlink && sortlink_data"/>
        </div>
        <div class="list" v-if="!is_loading && data.length === 0">
            <NoData :image="require('@/assets/images/pc.png')" :title="no_data_context.title" :text="no_data_context.content" />
        </div>
        <Loading v-if="is_loading && is_loading_page && !is_swiper" class="static" />
    </aside>
</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Button from '@/components/ui/Button.vue';
import Dropdown from '@/components/ui/Dropdown.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import ProductItem from '@/components/product/ProductItem.vue';
import ModalSide from '@/components/ui/ModalSide.vue';
import Loading from '@/components/ui/Loading.vue';
import NoData from '@/components/creator/NoData.vue';
import moment from 'moment';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/scss';
import Checkbox from '@/components/ui/Checkbox.vue';
import formatMoney from '@/utils/formatMoney';
import SValue from '@/components/summary/SummaryValue.vue';
import { saveSortLink } from '@/utils/SwitchFun';

export default {
    name: 'ui-product-list',

    components: {
        SvgIcon,
        Button,
        Dropdown,
        DatePicker,
        ProductItem,
        ModalSide,
        Loading,
        NoData,
        Swiper,
        SwiperSlide,
        Checkbox,
        SValue,
    },

    data() {
        return {
            sort_select: [
                { id: 1, text: 'Sort: Product Views (Descending)' },
                { id: 2, text: 'Sort: Product Views (Ascending)' },
                { id: 3, text: 'Sort: Clicks (Descending)' },
                { id: 4, text: 'Sort: Clicks (Ascending)' },
                { id: 5, text: 'Sort: Sales (Descending)' },
                { id: 6, text: 'Sort: Sales (Ascending)' },
                { id: 7, text: 'Sort: Sales Value (Descending)' },
                { id: 8, text: 'Sort: Sales Value (Ascending)' },
            ],
            sort_type: 1,
            listScroll: null,
            date: {
                start: '',
                end: ''
            },
            no_data: {

            },
            start_watch:false,//防止元件太早刷新
            sortlink_data:0,
        }
    },

    props: {
        title: {
            type: String,
            default: '',
        },
        is_swiper: {
            type: Boolean,
            default: false,
        },
        show_date_picker: {
            type: Boolean,
            default: false,
        },
        date_range: {
            type: Object,
            default: null,
        },
        sort_index: {
            type: Number,
            default: 0,
        },
        last_update: {
            default: '',
            type: String,
        },
        data: {
            type: Array,
            default: [],
        },
        is_loading: {
            type: Boolean,
            default: false,
        },
        is_loading_page:{
            type: Boolean,
            default: false,
        },
        no_data_context: {
            type: [Object, String],
            default: '',
        },
        data_info:{
            type: Object,
            default: null
        },

        data_statistic:{
            type: Object,
            default: null
        },

        show_sortlink:{
            type: Boolean,
            default: false
        },

        init_sortlink:{
            type:[Boolean,Number],
            default:undefined
        },

        show_data_statistic:{
            type: Boolean,
            default: true
        },
        show_last_datetime:{
            type: Boolean,
            default: false
        }
        // creator_id: {
        //     type: Number,
        //     default: 0,
        // },
    },

    setup() {
        return {
            modules: [Navigation],
        };
    },

    computed:{
        plusData(){
            let da=this.data_statistic;
            console.log('da',da)
            let can_toggle_sortlink= da ? da.short_link_click_sum + da.short_link_sales_sum + da.short_link_sales_value_sum : 0;
            if(!da){
                return{
                    all_click: 0,
                    all_sales: 0,
                    all_sales_value: 0,
                    can_toggle_sortlink:can_toggle_sortlink
                }
            }if(this.sortlink_data){
                return{
                    all_click: da.click_sum + da.short_link_click_sum,
                    all_sales: da.sales_sum + da.short_link_sales_sum,
                    all_sales_value: da.sales_value_sum + da.short_link_sales_value_sum,
                    can_toggle_sortlink:can_toggle_sortlink
                }
                
            }else{
                return{
                    all_click: da.click_sum,
                    all_sales: da.sales_sum,
                    all_sales_value: da.sales_value_sum,
                    can_toggle_sortlink:can_toggle_sortlink
                }
            }
        },
        //判斷能不能把sortlink展現出來，如果不能，last_time也不能
        can_show_checkbox(){
            return this.start_watch && this.data_statistic && this.plusData.can_toggle_sortlink && this.show_sortlink
        }
    },

    watch:{

    },

    methods: {
        formatMoney: formatMoney,

        sortLinkClick(){
            this.sortlink_data=this.sortlink_data?  0 : 1;
            this.$emit('handleSortLink', this.sortlink_data );
        },

        updateDate(obj) {
            this.date.start = moment(obj.start_date).format('YYYY-MM-DD');
            this.date.end = moment(obj.end_date).format('YYYY-MM-DD');
            let newobj = {
                type: this.sort_type,
                date: {
                    start: this.date.start,
                    end: this.date.end
                }
            }
            this.$emit('update-sort', newobj);
            // this.fetchData();
        },

        updateSort(id) {
            this.sort_type = id;
            this.date.start= moment(this.date_range.start_date).format('YYYY-MM-DD');
            this.date.end= moment(this.date_range.end_date).format('YYYY-MM-DD')
            let obj = {
                type: this.sort_type,
                date: {
                    start: this.date.start ,
                    end: this.date.end,
                }
            }
            this.$emit('update-sort', obj);
            // this.fetchData();
        },

        open(data) {
            this.date.start= moment(this.date_range.start_date).format('YYYY-MM-DD');
            this.date.end= moment(this.date_range.end_date).format('YYYY-MM-DD')
            let d = {
                "product_id": data.id,
                "start_date": this.date.start,
                "end_date": this.date.end,
                "init_sortlink":this.sortlink_data
            };
            if(this.data_info) d.data_info=this.data_info;
            this.EventBus.emit('open-product-modal', d);
            
        },

    },

    mounted(){
        this.sortlink_data = this.init_sortlink!==undefined ? this.init_sortlink : this.$store.state.save_sortlink_data;//放入預設
        this.start_watch=true;
    },
}
</script>
<style lang="scss">
.ui-product-list {
    position: relative;
    overflow: hidden;
    width: 100%;

    .list-header {
        display: flex;

        .title {
            font: var(--font-demi-20);
            height: 48px;
            display: flex;
            align-items: center;
            color: var(--black-90);
            flex: 1;

            span {
                margin-left: 16px;
                font: var(--font-fg-12);
            }
        }

        .col {
            display: flex;
            align-items: center;

            span {
                height: 24px;
                width: 24px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                margin-left: 10px;
                border-radius: 2px;
                transition: all .2s ease-in-out;
                color: var(--black-90);

                &:hover {
                    background-color: var(--bg-03);
                }

                svg {
                    height: 10px;
                    width: 10px;
                    position: relative;
                    transform: rotate(90deg);
                }

                &.swiper-button-next {
                    svg {
                        transform: rotate(-90deg);
                    }
                }

                &.swiper-button-disabled {
                    cursor: not-allowed;
                    color: var(--black-40);
                    pointer-events: none;
                }
            }
        }

        .btn-box {
            display: flex;
            align-items: center;
            cursor: pointer;

            .ui-dropdown {
                margin: 0 8px;
            }

            time {
                font-size: 12px;
                color: var(--black-75);
                padding-right: 12px;
                margin-left: 8px;
            }
        }

    }

    .report-box {
        margin-top: 8px;
        margin-bottom: 24px;
        display: flex;
        border: 1px solid rgba(0, 0, 0, 0.08);

        .report-item {
            padding: 24px 16px;
            width: 25%;
            background: white;
            border-right: 1px solid rgba(0, 0, 0, 0.08);
            .the-type{
                width: 100%;
                padding: 0;
                border:none;
            }

            &:last-child{
                border-right: none;
            }

        }
    }

    .loading {
        margin-top: 20px;
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4px;

        .product-item {
            width: calc(25% - 8px);
            display: block;
            margin: 4px;
        }
    }

    .swiper-wrapper {
        display: flex;

        .swiper-slide {
            width: 236px;
            padding: 0 8px;
            box-sizing: content-box;

            &:last-child {
                padding-right: 0;
            }

            &:first-child {
                padding-left: 0;
            }
        }
    }

    .update-time{
        cursor: auto;
    }
}
</style>