<template>
    <div class="top-brand-post-box">
        <div class="title" v-if="had_title && (( is_brand && !creator_no_connect) || !is_brand )"><span>{{ title }}</span></div>

        <div :class="['tab-box', !had_navi && 'tab-box-one']" v-if="had_title">
            <Navi v-if="had_navi" :type="1" :menu="posts_value.top_menu" :type_one_index="posts_value.top_menu_index"
                :key="posts_value.top_menu_index" @selected-tab="selectTop" />

            <Button v-if="!is_hide_tag && had_tag && (tags.length > 0 || (tags.length === 0 && $store.state.user_data.contract_status))"
                size="32" :color="'black-60'" :style="'border-square'" :class="{ 'disabled': !selected_tags.length && posts.length === 0 || brand_post_empty }"
                @click="$refs.pop.open(false);">
                <span>{{ tag_text }}</span>
                <svg-icon name="arrow" class="icon-right" />
            </Button>
            <DropdownHashtag v-if="!is_hide_tag" :tags="tags" :otherTag="otherTag" :selected_tags="selected_tags"
                @select-all="selectAll" @select-tag="selectTag" ref="pop" :is_anchor_post="true"/>

            <Dropdown :items="posts_value.sort_select" :direction_type="'right'" class="edit-button" :icon="'filter'"
                :disabled="!posts || posts.length === 0 || brand_post_empty" :default_value="posts_value.sort_index"
                @update-dropdown="updateSort" :key="posts_value.drop_key" />

            <slot name="button"></slot>
        </div>

        <div class="sticker-tab-box-inside" v-if="now_tab === 0 && !posts_value.loading ">
            <Button type="button" color="dark-blue" size="32" v-for="(v, k) in posts_value.ig_menu" :key="k"
                :style="'no-border-square'" @click="clickIgTab(v.index,true)"
                :class="{ 'actived': posts_value.ig_post_type === v.index }">
                {{ v.text }} ({{ v.value }})
            </Button>
        </div>

        <slot name="tabs"></slot>

        <Loading v-if="posts_value.loading || tag_loading" class="static" />

        <!-- swiper-box -->
        <div v-if="none_empty_swiper_post && is_swiper && !brand_post_empty" >
            <div class="post-list-box top-swiper-box" v-for="(items, key) in posts" :key="key" v-show="items.data.length > 0">
                <div class="title" v-if="items.which && items.title_show"><svg-icon :name="items.svg" />{{ items.which }} ({{items.total}} total)</div>
                <div class="small-title" v-if="items.title">{{ items.title }} ({{ items.data.length }})</div>
                <div class="col">
                    <span class="arrow" :class="'swiper-button-prev swiper-button-prev-' + key">
                        <svg-icon name="arrow" />
                    </span>
                    <span class="arrow" :class="'swiper-button-next swiper-button-next-' + key">
                        <svg-icon name="arrow" />
                    </span>
                </div>
                <swiper :modules="modules" :slides-per-view="'auto'" :slides-per-group="3" :navigation="{
                        nextEl: '.swiper-button-next-' + key,
                        prevEl: '.swiper-button-prev-' + key
                    }">
                    <swiper-slide v-for="(v, k) in items.data" :key="k">
                        <CreatorSmallCard :data="v" @click.stop.native="$emit('clickPost', v)" :tag_select="tags"
                        @edit-tag="editTag" :no_plus="no_plus" :now_ig_type="posts_value.ig_post_type" :ambassador_user_application_record_id="ambassador_user_application_record_id"
                        :creator_show="creator_show" :nowIg_tab="now_tab === 1 ? 3 : nowIg_tab" :otherTag="otherTag" />
                    </swiper-slide>
                </swiper>
            </div>
        </div>

        <!-- list-box -->
         <!-- {{ posts.length }} - {{ posts_value.loading }} -->
        <div class="post-list-box" v-if=" !tag_loading && posts.length > 0 && !posts_value.loading && !is_swiper && !brand_post_empty && (posts[0] && !posts[0].svg)">
            <div class="list-item" v-for="( v, k ) in  posts" :key="k">
                <CreatorSmallCard :data="v" @click.stop.native="$emit('clickPost', v)" :tag_select="tags"
                    @edit-tag="editTag" :no_plus="no_plus" :now_ig_type="posts_value.ig_post_type" :ambassador_user_application_record_id="ambassador_user_application_record_id"
                    :creator_show="creator_show" :nowIg_tab="now_tab === 1 ? 3 : nowIg_tab" />
            </div>
        </div>
        <Loading v-if="posts_value.next_loading" class="small"/>

        <NoData v-if="((posts.length === 0 && !is_swiper) || (is_swiper && !none_empty_swiper_post) || brand_post_empty  )  && !posts_value.loading"
            :image="!had_m_ht ? require('@/assets/images/pc.png') : require('@/assets/images/pc.png')"
            @title-function="goSetting" @text-function="goSetting"
            :title="empty_value.title" :text="empty_value.text" >
            <p class="have-problem" v-if="creator_no_connect"> Having a problem?  
                <a
                :href="`mailto:anchor.support@motom.me?subject=${$store.state.user_data.merchant_account ? $store.state.user_data.merchant_account.merchant_display_name + ' - ' : ''}Support Request&amp;body=Please%20describe%20your%20issue%20below%20in%20as%20much%20detail%20as%20possible%20(including%20what%20device%20you're%20using%20and%20screenshots,%20if%20necessary).%20A%20support%20team%20member%20will%20contact%20you%20within%201-2%20business%20days.`"
                target="_blank">
                &nbsp;Contact us.
                </a>
            </p>
            <Button size="32" color="dark-blue" @click="goSetting" v-if="!had_m_ht && !is_soc && !creator_no_connect">
                <svg-icon name="plus" /> Update Settings
            </Button>
        </NoData>


    </div>
</template>

<script>
import Dropdown from '@/components/ui/Dropdown.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/scss';
import CreatorSmallCard from '@/components/CreatorSmallCard.vue';
import { Navigation } from 'swiper/modules';
import NoData from '@/components/creator/NoData.vue';
import Navi from '@/components/Navi.vue';
// import moment from 'moment';
import Button from '@/components/ui/Button.vue';
import Modal from '@/components/ui/Modal.vue';
import Hashtag from '@/components/Hashtag.vue';
import Loading from '@/components/ui/Loading.vue';
import DropdownHashtag from '@/components/DropdownHashtag.vue';
// import { handleSelectText, compareArrays } from '@/utils/ContentSwitchFun.js';

export default {
    name: 'top-brand-post',
    emits: ['clickPost','creator_no_connect-fun','updateSort','updateTag','selectTop','callApiAgain','changeIgTag','findStop'],

    data() {
        return {
            tags: [],
            had_m_ht: true,
            nowIg_tab: 0,//ig post=>0 ,reel=>1 story=>2 ,tk=>3
            otherTag: {
                hastags: [],
                instagram_mentions:[],
                tiktok_mentions:[],
            },
            tag_loading:false,
        };
    },

    setup() {
        return {
            modules: [Navigation],
        };
    },

    computed:{
        none_empty_swiper_post(){
            return !this.is_swiper || (this.posts.length > 0 && this.posts.some(post => post.data.length > 0));
        },
        brand_post_empty(){
            return !this.had_m_ht && !this.is_soc;
        },
        creator_no_connect(){
            let da=this.$store.state.creator_data;
            if(da===null){
                return false;
            }
            else if( 
                (this.had_navi && this.posts_value.top_menu_index===0 && !da.tiktok_connect_status) || 
                (this.had_navi && this.posts_value.top_menu_index===1 && !da.instagram_connect_status) || 
                (!this.had_navi && this.now_tab===0 &&  !da.instagram_connect_status) || 
                (!this.had_navi && this.now_tab===1 &&  !da.tiktok_connect_status) 
            ){
                return true;
            }else{
                return false;
            }
        },
        empty_value(){
            let title='No available posts yet';//'This social account is not yet connected';
            let text='';
            let da=this.$store.state.creator_data;

            //ticket 8806
            if(this.is_soc){
                text='';
            }
            else if(this.creator_no_connect){
                    title='This social account is not yet connected';
                    text=`Once they authorize this account on Motom, content that includes your @mentions and #hashtags will appear here.`;
            
            }else if(!this.had_m_ht && !this.is_soc){
                title=`Content will become available when you add Instagram and TikTok #hashtags and @mentions to track in your <span class='link' >settings</span>.`;
                text=`The creator must connect their socials for the content to appear.`;
            }else{
                if( da===null){
                    text='';
                }
                else{
                    text=`This creator’s content which includes your @mentions and @hashtags will appear here.`
                }   
            }
           
            return {
                title:title,
                text:text
            }
        }
    },

    components: {
        Swiper,
        SwiperSlide,
        CreatorSmallCard,
        NoData,
        Dropdown,
        Navi,
        Button,
        Modal,
        Hashtag,
        Loading,
        DropdownHashtag,
    },

    props: {
        posts: {
            type: Array,
            default: []
        },

        posts_value: {
            type: Object,
            default: {}
        },

        title: {
            type: String,
            default: 'Top 20 Branded Posts'
        },

        had_title: {
            type: Boolean,
            default: true,
        },

        had_navi: {
            type: Boolean,
            default: true,
        },

        had_tag: {
            type: Boolean,
            default: true,
        },

        tag_text: {
            type: String,
            default: 'Tag:All'
        },

        selected_tags: {
            type: Array,
            default: []
        },

        no_plus: {
            type: Boolean,
            default: false,
        },
        is_soc: {
            type: Boolean,
            default: false,
        },
        is_brand: {
            type: Boolean,
            default: false,
        },
        now_tab: {
            type: Number,
            default: 0,//0=>ig,1=>tk {{outside tab}}
        },
        creator_show: {
            type: Boolean,
            default: false,
        },

        is_swiper:{
            type: Boolean,
            default: false,
        },
        
        is_hide_tag:{
            type: Boolean,
            default: false,
        },
        
        is_click_by_user:{
            type: Boolean,
            default: false,
        },

        ambassador_user_application_record_id:{
            type: Number,
            default: 0,
        },

    },

    watch: {
        creator_no_connect(val){
            this.$emit('creator_no_connect-fun', val);
        }
    },

    methods: {
        updateSort(obj) {
            this.$emit('updateSort', obj);
        },

        selectAll() {
            this.$emit('updateTag', false);
        },

        selectTag(tag) {
            this.$emit('updateTag', tag);
        },

        selectTop(id) {
            this.nowIg_tab = id === 0 ? 3 : this.nowIg_tab;
            this.$emit('selectTop', id, true);
        },

        updateTag(arr) {
            //this.tag_text = handleSelectText(arr);
            this.$emit('updateTag', arr);
        },


        updateEditTags(o) {
            this.$emit('callApiAgain');
        },

        editTag(obj) {
            // console.log('editTag', obj)
            this.is_show_modal = true;
        },

        getTags() {
            if (this.$store.state.user_data) {
                this.tag_loading=true;
                this.$ajax(`creator/get-social-post-filter`, {
                    method: 'get',
                }).then(res => {
                    const data = res.data.data;
                    if (!data) return;
                    data.social_post_mention_texts = data.social_post_mention_texts ? data.social_post_mention_texts : [];

                    this.tags = data.social_post_tag_texts;
                    this.otherTag.hastags = data.social_post_hashtag_texts;
                    this.otherTag.instagram_mentions= data.social_post_instagram_mention_texts;
                    this.otherTag.tiktok_mentions=data.social_post_tiktok_mention_texts;
                    this.had_m_ht = (data.social_post_hashtag_texts.length === 0 && data.social_post_mention_texts.length === 0) ? false : true;
                    this.tag_loading=false;
                });
            }
        },

        clickIgTab(index,is_click_by_user) {
            this.nowIg_tab = index;
            this.$emit('changeIgTag', index, is_click_by_user);
        },

        goSetting() {
            //this.$store.state.user_data.contract_status && 
            if (!this.had_m_ht) {
                window.location.href = '/settings/content-listening';
            }
        },

        handleIfDefaultNoData(){
            //If tk have no data, but ig does, default should show ig tab
            //這邊要注意tk的this.nowIg_tab設定為3(//ig post=>0 ,reel=>1 story=>2 ,tk=>3)
            //但tk在posts_value.top_menu_index為0 (ig為1)
            if(this.had_navi && this.posts.length===0 && !this.is_click_by_user ){
                
                if( this.posts_value.top_menu_index ===0 ){
                    this.posts_value.top_menu_index=1;
                    this.$emit('selectTop', 1);
                }
                //如果ig也沒有，去找IG的每一個，都沒有在回tk
                else{
                    let found = false;
                    this.posts_value.ig_menu.some((item,key) => {
                        if (item.value > 0) {
                            this.clickIgTab(item.index);
                            found = true;
                            return true;
                        }
                        return false;
                    });

                    if (!found) {
                        this.posts_value.top_menu_index=0;
                        this.$emit('selectTop', 0);
                        this.$emit('findStop');
                    }
                }
            }


        },

    },
    mounted() {
        this.EventBus.on('updated-all-post-tag', (tags) => {
            this.tags = tags;
        });

        this.getTags();
        this.handleIfDefaultNoData();
        this.$emit('creator_no_connect-fun', this.creator_no_connect);
    }
};
</script>

<style lang="scss">
@mixin flexCenter() {
    display: flex;
    align-items: center;
}

.top-brand-post-box {
    border-radius: 8px;

    .sticker-tab-box-inside {
        display: flex;
        align-items: center;
        margin: 8px;

        .ui-button {
            margin-right: 16px;
            display: inline-flex;
            background-color: unset;
            text-wrap: nowrap;
            border: none;

            &:hover {
                background-color: var(--bg-02);
            }

            &.actived,
            &.actived:hover {
                border: 1px solid var(--dark-blue) !important;
                color: var(--dark-blue);
                background-color: white;
            }
        }
    }

    .title {
        display: flex;
        align-items: center;
        font: var(--font-demi-20);
        color: var(--black-90);
        margin-bottom: 4px;

        span {
            flex: 1;
        }
    }

    .tag-drop {
        margin-right: 8px;

        .edit-button {
            margin: 6px 8px;
        }

    }

    .tab-box {
        @include flexCenter();
        margin: 12px 0;
        position: relative;

        &.tab-box-one {
            justify-content: flex-end;
            margin-top: -28px;
        }

        .ui-button{
            font:var(--font-fg-12);
            margin-right: 8px;
            .svg-icon {
                width: 10px;
                height: 10px;
                margin-left: 4px;
            }
        }

        .dropdown-hashtag{
            top: 28px;
            right: 180px;
        }

        .ui-navi {
            flex: 1;
            margin-top: 0 !important;
        }
    }

    .post-list-box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 8px;
        //overflow-x: hidden;
        background-color: var(--light-neutral);
        padding: 8px;
        border-radius: 8px;

        &.top-swiper-box{
            display: unset;
            padding: unset;
            background-color: unset;
            margin-bottom: 8px;
            position: relative;

            .swiper{
                margin: 8px 0;
            }

            .title{
                font:var(--font-med-16);
                padding: 8px 0 0!important;

                .svg-icon{
                    width: 24px;
                    height: 24px;
                    margin-right: 6px;
                }
            }

            .small-title{
                font:var(--font-med-14);
            }

            .col {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: absolute;
                right: 0;
                top: 0px;

                span {
                    height: 24px;
                    width: 24px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    margin-left: 10px;
                    border-radius: 2px;
                    transition: all .2s ease-in-out;
                    color: var(--black-90);

                    &:hover {
                        background-color: var(--bg-03);
                    }

                    svg {
                        height: 10px;
                        width: 10px;
                        position: relative;
                        transform: rotate(90deg);
                    }

                    &.swiper-button-next {
                        svg {
                            transform: rotate(-90deg);
                        }
                    }

                    &.swiper-button-disabled {
                        cursor: not-allowed;
                        color: var(--black-40);
                        pointer-events: none;
                    }
                }
            }

            &:nth-child(1) .col {
                top: 8px;
            }

            &:nth-child(2) .col {
                top: 32px;
            }

            .swiper-wrapper {
                display: flex;

                .swiper-slide {
                    width: 236px;
                    padding: 0 8px;
                    box-sizing: content-box;

                    &:last-child {
                        padding-right: 0;
                    }

                    &:first-child {
                        padding-left: 0;
                    }
                }
            }
        }

        @media (max-width: 1200px) {
            grid-template-columns: repeat(3, 1fr);
        }

        // .list-item {
        //     overflow: hidden;
        // }
    }



    .creator-no-data {
        .ui-button {
            margin: 16px auto;
        }
    }
}
</style>
