<template>
    <div class="product-item">
        <figure class="image-wrap">
            <img :src="data.product.images.img_400x400" />
        </figure>
        <div class="product-name" v-tippy="{ content: data.product.name, delay: [500, 20] }">{{ data.product.name }}
        </div>
        <div class="product-price">${{
                data.product.on_sale === 1 ? formatMoney(data.product.sale_price) : formatMoney(data.product.price) }}</div>
        <div class="product-data">
            <div class="data-item">
                <div class="value">{{ formatNumber(data.views) }}</div>
                <div class="text">Product Views</div>
            </div>
            <div class="data-item">    
                <div class="value">{{ formatNumber(data.click) }}<i class="lig" v-if="data.click_include_shortlink_data"><svg-icon  name="lightning" /></i></div>
                <div class="text">Clicks</div>
            </div>
            <div class="data-item">  
                <div class="value">{{ formatNumber(data.sales) }}<i class="lig" v-if="data.sales_include_shortlink_data"><svg-icon  name="lightning" /></i></div>
                <div class="text">Sales</div>
            </div>
            <div class="data-item">
                <div class="value">${{ formatMoney(data.sales_value) }}<i class="lig" v-if="data.sale_value_include_shortlink_data"><svg-icon  name="lightning" /></i></div>
                <div class="text">Sales Value</div>
            </div>
            <!-- <div class="prodcut-desc" v-if="show_data_link && this.show_sortlink_text">
                *Includes Shortlink Data
            </div> -->
        </div>
        <div class="product-date">
            <span>Added: {{ $filters.momentFormat(data.created_at, 'MMM') + '&nbsp;' +
                $filters.momentFormat(data.created_at, 'D') + ', ' + $filters.momentFormat(data.created_at, 'YYYY')
                }}</span>
            <a class="icon" v-tippy="{ content: 'Open product source in new tab', delay: [500, 20] }" target="_blank"
                :href="data.product.product_url" @click.stop>
                <SvgIcon name="share" />
            </a>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import formatMoney from '@/utils/formatMoney';

export default {
    name: 'ProductItem',

    components: {
        SvgIcon,
    },

    // data() {
    //     return {

    //     }
    // },

    props: {
        data: {
            type: Object,
            default: {},
        },
        show_data_link:{
            type: [Number, Boolean],
            default: 0,
        }
    },

    computed:{
        //目前新增以下三欄位但沒用到8882
        show_sortlink_text(){
            return this.data && (this.data.sale_value_include_shortlink_data || this.data.sales_include_shortlink_data || this.data.click_include_shortlink_data)
        }

    },

    methods: {
        formatNumber(num) {
            return num ? num.toLocaleString('en-US') : '---';
        },

        share() {
            const obj = {};
            obj.url = 'test';
            this.EventBus.emit('open-share', obj);
        },
        formatMoney: formatMoney,
    },
}
</script>
<style lang="scss">
.product-item {
    background: white;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;
    cursor: pointer;

    .image-wrap {
        cursor: pointer;
        position: relative;
        overflow: hidden;
        display: block;

        &:before {
            content: "";
            display: block;
            padding: 0 0 100%;
        }

        img {
            display: block;
            position: absolute;
            left: 0%;
            top: 0%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .product-name {
        height: 42px;
        padding: 10px 8px 0 8px;
        font: var(--font-fg-12);
        color: var(--black-90);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .product-price {
        padding: 0 8px 10px 8px;
        font: var(--font-med-12);
        color: var(--black-90);
    }

    .product-data {
        padding: 8px;
        background: var(--bg-10);
        // display: inline-block;

        .data-item {
            width: 50%;
            display: inline-block;

            &:nth-child(3),
            &:nth-child(4) {
                padding-top: 8px;
            }

            .text {
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                color: var(--black-50);
            }

            .value {
                font: var(--font-med-12);
                color: var(--black-90);

                display: flex;
                align-items: center;

                svg{
                    fill:var(--black-90);
                    margin-left: 2px; 
                    width: 12px;
                    height: 12px;
                }
            }
        }

        .prodcut-desc{
            width:100%;
            font-size: 12px;
            color: var(--black-50);
            margin-top: 8px;
        }
    }

    .product-date {
        padding: 14px 8px;
        font: var(--font-fg-12);
        color: var(--black-50);
        display: flex;

        &>span {
            flex: 1;
        }

        .icon {
            padding-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                width: 12px;
                height: 12px;
                fill: var(--black-60);
            }
        }
    }
}
</style>
