<template>
    <div class="creator-data">
        <div :class="['text', had_slot && 'text-spec']">
            <div class="left-box">
                <svg-icon :name="content_value.icon"
                    :class="{ 'disabled-text': top_value === 'Storefront' && !data_info.motom_user_id }" :data-id="data_info.motom_user_id"/>
                <tippy :content="showFullText(data_info, content_value.show_val)"
                    @mouseover="isEllipsisActive($event, data_info)">
                    <div class="value"
                        :class="{ 'empty': !top_value, 'text-ellipsis-link': top_value, 'value-w': had_slot, 'disabled-text': top_value === 'Storefront' && !data_info.motom_user_id }"
                        @click.stop="openLink(content_value)">
                        {{ (top_value) ? top_value : '---' }}</div>
                </tippy>
            </div>
            <slot name="head"></slot>

            <div class="right" v-if="had_soc_status">
                <div class="soc-state"
                    :class="['class-' + soc_status ]" >
                    {{ soc_status_text }}
                </div>
            </div>
        </div>
        <div :class="['under-box', had_inf && 'under-box-spec']" v-if="top_value === 'Storefront'">
            <div class="item" v-for="(item, key) in content_value.under" :key="key">
                <div class="content">{{ item.content }}</div>
                <div class="name">{{ item.name }}</div>
            </div>
            <slot name="item"></slot>
        </div>
        <div v-else :class="['under-box', 'social-box', had_inf && 'under-box-spec']">
            <div class="item" v-for="(item, key) in content_value.under" :key="key">
                <div class="content">{{ item.content }}</div>
                <div class="name">{{ item.name }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { goMedia } from '@/utils/goMedia';

export default {
    name: 'CreatorModalSideBox',


    props: {
        data_info: {
            default: {},
            type: Object,
        },
        top_value: {
            default: '',
            type: String,
        },
        content_value: {
            default: {},
            type: Object,
        },
        had_slot: {
            default: false,
            type: Boolean,
        },
        had_inf: {
            default: false,
            type: [Boolean, Number],
        },

        had_soc_status:{
            default: false,
            type: Boolean,
        },

        soc_status:{
            default: false,
            type: [Boolean, Number],
        }
    },

    computed:{
        soc_status_text(){
            let text='';
            switch (this.soc_status) {
                case 0:
                    text = 'Not Connected';
                    break;
                case 10:
                    text = 'Partial Connected';
                    break;
                case 15:
                    text = 'Expired';
                    break;
                case 20:
                    text = 'Fully Connected';
                    break;
                case 25:
                    text = 'Expired';
                    break;
            }
            return text;
        }
    },

    methods: {
        GoMedia: goMedia,
        openLink(content_value) {
            if (this.top_value !== 'Storefront') {
                this.GoMedia(content_value.go_val, this.top_value)
            }
        },
        showFullText(row, type) {
            let text = '';
            if (row.is_ellipsis_active) {
                // return row.last_name + ' ' + row.last_name;
                switch (type) {
                    case 'name':
                        text = row.first_name + ' ' + row.last_name;
                        break;
                    case 'ins':
                        text = row.instagram_handle;
                        break;
                    case 'tt':
                        text = row.tiktok_handle;
                        break;
                    case 'email':
                        text = row.email;
                        break;
                }
            } else {

                switch (type) {
                    case 'name':
                        text = row.first_name + ' ' + row.last_name;
                        break;
                };

            }

            return text;
        },

        isEllipsisActive(e, row) {
            row.is_ellipsis_active = (e.target.offsetWidth < e.target.scrollWidth);
            //target

        },
    },
}
</script>
<style lang="scss">
@mixin textOver() {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
}

.creator-data {
    padding-right: 10px;
    background: var(--bg-10);
    padding: 12px 16px;
    overflow: hidden;


    .svg-icon--storefront {
        cursor: default !important;

        & + span .value {
            cursor: default !important;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .left-box{
        display: flex;
        flex:1;
        align-items: center;
    }

    .soc-state{
        color: var(--black-50);
        font-size: 12px;
        display: inline-flex;
        align-items: center;
        line-height: 18px;

        &::before {
            height: 8px;
            width: 8px;
            content: "";
            display: block;
            border-radius: 50%; /* 完整圓形 */
            background: linear-gradient(to right, var(--yellow) 50%, transparent 50%); /* 左半邊填充顏色 */
            border: 1px solid var(--yellow); /* 整個圓的邊框 */
            margin-right: 5px;
        }

        //fully connected 
        &.class-20{
            &::before {
                background: var(--green);
                border: 1px solid var(--green);
            }
        }

        //partial connected
        &.class-10{
            &::before {
                background: linear-gradient(to right, var(--green) 50%, transparent 50%); /* 左半邊填充顏色 */
                border: 1px solid var(--green); /* 整個圓的邊框 */
            }
        }

        //fully connected expired
        &.class-25{
            &::before {
                background:var(--alert);
                border: 1px solid var(--alert);
            }
        }

        //partial connected expired
        &.class-15{
            &::before {
                background: linear-gradient(to right, var(--alert) 50%, transparent 50%); /* 左半邊填充顏色 */
                border: 1px solid var(--alert); /* 整個圓的邊框 */
            }
        }

        //not connected
        &.class-0{
            &::before {
                background: var(--neutral); 
                border: 1px solid var(--neutral); /* 整個圓的邊框 */
            }
        }
    }

    .text {
        display: flex;
        align-items: center;
        margin-bottom: 9px;

        svg {
            margin-right: 8px;
            width: 16px;
            height: 16px;
            display: block;
            cursor: pointer;
            fill: var(--black-90);
        }

        .right {
            display: flex;
            justify-content: flex-end;

            .value{
                font-family: var(--font);
            }
        }

        span {
            width: 100%;
            overflow: hidden;
        }

        &.text-spec {
            span {
                flex: 1;
            }
        }

    }

    .under-box {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;

        &.social-box,
        &.under-box-spec {
            grid-template-columns: 1fr 1fr 1fr;
        }

        &.social-box {
            .item {
                &:nth-child(1)::after {
                    border-right: 1px solid var(--gray-blue);
                    position: absolute;
                    content: '';
                    right: 10px;
                    top: 6px;
                    bottom: 0;
                }

                &:nth-child(2)::before {
                    position: absolute;
                    content: 'Last 30 days';
                    left: 0px;
                    top: 2px;
                    color: var(--black-75);
                    font: var(--font-med-10);
                }
            }
        }

        .item {
            padding: 16px 0 0;
            overflow: hidden;
            position: relative;

            &:last-child {
                margin-right: 0;
            }

            .name {
                font: var(--font-med-12);
                color: var(--dark-gray-blue);
                @include textOver();
            }

            .content {
                font: var(--font-demi-14);
                color: var(--dark-blue);
                margin-bottom: 4px;
                @include textOver();
                display: flex;
                align-items: center;
            }
        }
    }

    .link-box {
        color: var(--blue);
        margin-left: 6px;
        cursor: pointer;

        svg {
            height: 12px;
            display: block;
            width: 12px;
        }
    }

    .store-date {
        font: var(--font-med-10);
        color: var(--black-50);
        max-width: 65px;
        display: flex;
        align-items: center;
    }

    .value {
        font: var(--font-med-14);
        font-size: 12px;
        color: var(--black-90);
        display: flex;
        align-items: center;
        @include textOver;

        &.value-act {
            margin-left: 8px;
            width: auto;
            font-size: 12px;
            display: inline-flex;
            align-items: center;

            &::before {
                height: 9px;
                width: 9px;
                content: '';
                display: block;
                border-radius: 5px;
                background-color: var(--yellow);
                margin-right: 5px;
            }
        }

        &.value-w {
            min-width: 65px;
        }

        &.empty {
            letter-spacing: 1px;
        }

        &.invited,
        &.disabled {
            color: var(--yellow);
        }

        &.declined {
            color: var(--alert);
        }

        &.active {
            color: var(--green);
            &::before {
                background-color: var(--green);
            }
        }

        &.not.available {
            color: var(--gray-blue);
            pointer-events: none;
        }

        .ui-switch {
            margin-left: 9px;
        }
    }

    .disabled-text {
        text-decoration: none !important;
        cursor: unset !important;
    }

    .resend {
        display: flex;
        justify-content: right;
        align-items: center;
        font: var(--font-fg-12);
        font-weight: 400;
        color: var(--blue);
        cursor: pointer;
    }
}
</style>