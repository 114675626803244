<template>
    <div class="dropdown-hashtag" ref="el" :class="{'show': is_show}" @click.stop="handleClick" :style="drop_style">
        <Loading v-if="is_updateing" class="static" />
        
        <div v-else :key="list_key">
            <div :class="['small-label', (tags.length === 0 && !is_anchor_post) && 'label-center']">
                <h3>
                    {{ title }}
                </h3>
                <svg-icon name="close" @click.stop="close()"/>
            </div>
            <div :class="['input', ( tags.length === 0 && !is_anchor_post )&& 'input-center']" v-if="!is_edit">
                <div class="input-box">
                    <input type="text" v-model="new_tag.text" placeholder="Add new tag" @keyup.enter="add(0)" />
                    <label @click.stop="add(0)">Add</label>
                </div>
                <p class="alert" v-if="(tag_type !== 'brand_tag' && tags.length === 0 )|| ( tag_type==='brand_tag' && tags.length === 0  &&!is_anchor_post)">Example: Ambassador, Organic, Paid, <br>Holiday, etc.</p>
                <p class="error-text" v-if="is_error && error_text">{{ error_text }}</p>
            </div>

            <Scrollbar v-if="is_edit" class="scroll-tag-box" :show_bar="true">
                <ul >
                    <!-- creator_tag如果from_ambassador為0，代表可以編輯刪除 -->
                    <li class="check-li" v-for="(v, k) in ( tag_type === 'creator_tag' ? tags.filter(e => e.from_ambassador === 0 ) : tags) " >
                        <div v-if="!v.is_edit" class="check-li-box" @click.stop="selectDeleteTag(v)">
                            <Checkbox size="small" :key="k" :label_text="v.text" :checked="delete_tags.filter((t) => t.id === v.id).length !== 0"  />
                            <i v-if="tag_type === 'creator_tag'" class="pick-svg"><svg-icon  name="pen" @click.stop="v.temp=v.text; v.is_edit=true" /></i>
                        </div>
                        <div v-if="v.is_edit" class="check-li-box">
                            <input type="text" v-model="v.temp" placeholder="Edit tag"/>

                            <i class="pick-svg"><svg-icon name="close" @click.stop="v.is_edit=false"/></i>
                            <i class="pick-svg green" ><svg-icon name="yes-2" @click.stop="handleEditBtn(v)"/></i>
                        </div>
                    </li>
                </ul>
            </Scrollbar>

            <Scrollbar v-else class="scroll-tag-box" :show_bar="true">
                <ul> 
                    <!-- v-if="tags.length > 0" -->
                    <li class="check-li" @click.stop="selectAllTag()" v-if="tag_type==='brand_tag'">
                        <Checkbox v-if="tags.length > 0 && !no_all" size="small" :key="'all'" :label_text="'All'" :checked="selected_tags.length === 0"  />
                    </li>

                    <li class="check-li" @click.stop="selectthsTag({ id: -2, text: `In Storefront` })" v-if="tag_type==='brand_tag' && is_anchor_post">
                        <Checkbox size="small" :key="'In Storefront'" :label_text="'In Storefront'" :checked="selected_tags.filter((t) => t.id === -2).length !== 0"  />
                    </li>

                    <li :class="['check-li',disabled_check && 'hide-check-li']" v-for="(v, k) in tags" @click.stop="disabled_check? null : selectthsTag(v)">
                        <Checkbox size="small" :key="k" :label_text="v.text" :checked="selected_tags.filter((t) => t.id === v.id).length !== 0"  />
                    </li>
                </ul>
            </Scrollbar>

            <footer v-if="tags.length > 0">
                <span v-if="!is_edit" @click.stop="is_edit = true"><svg-icon name="edit" /> Edit</span>
                <ul class="footer-ul">
                    <li @click.stop="is_edit = false" ><span v-if="is_edit" class="cancel">Cancel</span></li>
                    <li :class="{'dis-li': delete_tags.length === 0}"  @click.stop="handleDelBtn()"><span v-if="is_edit" class="remove">Delete <svg-icon name="remove" /></span></li>
                </ul>
            </footer>
        </div>
    </div>

    <Modal :is_show="is_show_remove_modal" class="alert-modal alert-modal-indside" @click-bg="is_show_remove_modal = false">
        <div class="box remove-box">
            <header class="delete-header">{{ remove_text.title }}</header>
            <p>{{ remove_text.content }}</p>

            <div class="btns">
                <Button type="button" color="no-color" size="32" @click.stop="remove(false)">No</Button>
                <Button type="button" color="dark-blue" size="32" @click.stop="remove('remove')">Yes, confirm</Button>
            </div>
        </div>
    </Modal>

    <Modal :is_show="is_show_edit_modal" class="alert-modal alert-modal-indside" @click-bg="is_show_edit_modal = false">
        <div class="box remove-box">
            <header class="delete-header">Update this tag?</header>
            <p>{{`Updates will be made on ${$sotre.state.spec_value.del_tag_num} creators.`}}</p>

            <div class="btns">
                <Button type="button" color="no-color" size="32" @click.stop="saveEdit(false)">No</Button>
                <Button type="button" color="dark-blue" size="32" @click.stop="saveEdit(true)">Confirm</Button>
            </div>
        </div>
    </Modal>

</template>
<script>
import Loading from '@/components/ui/Loading.vue';
import Button from './ui/Button.vue';
import Modal from './ui/Modal.vue';
import Checkbox from './ui/Checkbox.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';

export default {
    name: 'DropdownHashtag',
    emits: ['select-all','select-tag','closeKey','update'],

    components: {
        Loading,
        Button,
        Modal,
        Checkbox,
        Scrollbar,
    },

    data() {
        return {
            is_show: false,
            is_updateing: false,
            is_show_remove_modal: false,
            is_show_edit_modal:false,
            is_edit: false,
            is_error: false,
            error_text:'',
            is_timer: false,
            delete_tags: [],
            new_tag: {
                id: 0,
                text: '',
            },
            remove_item: {
                tag: 0,
                list: 0,
                social:0,
                index: 0,
            },
            max: 10,
            list_key:0,
            now_edit_value:{},
        }
    },

    props: {
        tag_type:{
            type: String,
            default: 'brand_tag',//brand_tag//creator_tag
        },

        tags: {
            type: Array,
            default: () => [],
        },

        selected_tags: {
            type: Array,
            default: () => [],
        },

        otherTag:{
            type: Object,
            default: {},
        },

        no_all:{
            type: Boolean,
            default: false,
        },

        open_key:{ //針對每一個創作者的開啟方式
            type: Boolean,
            default: false,
        },

        row:{
            type: [Object, Array],
            default: () => [],
        },

        index:{
            type: Number,
            default: null,
        },

        disabled_check:{
            type: Boolean,
            default: false,
        },

        outside_remove:{
            type: Boolean,
            default: false,
        },

        drop_css:{
            type: String,
            default: '',
        },//table的相對位子左右兩邊各一個，對應的位子不一樣

        spec_tag:{
            type: Boolean,
            default: false,
        },//如果為True，不適用這裡面的handleclick

        is_anchor_post:{
            type: Boolean,
            default: false,
        }

    },

    watch:{
        open_key(val){
            if(val){
                this.open();
            }else{
                this.close()
            }
        },
        
        '$store.state.spec_value.confirm_remove_outside_modal'(val){
            if(val && this.is_show){
                this.$store.state.spec_value.confirm_remove_outside_modal=false;
                this.remove('remove');
            }
        },

        '$store.state.spec_value.confirm_edit_outside_modal'(val){
            if(val && this.is_show){
                this.$store.state.spec_value.confirm_edit_outside_modal=false;
                this.saveEdit(true);
            }
        },

        is_show_remove_modal(val){
            this.$store.state.spec_value.del_tag_modal=val;
        },

    },

    computed: {
        hide_edit() {
            return !this.$store.state.user_data.contract_status;
        },

        remove_text() {
            if(this.tag_type==='brand_tag'){
                return {
                    title: `Remove tags?`,
                    content: `This will stop tracking content on creator profiles.`
                }
            }else{
                return {
                    title: `Remove tags?`,
                    content: `The tag will be removed from ${this.$store.state.spec_value.del_tag_num} Creators.`
                }
            }
        },

        title(){
            //brand_tag//creator_tag
            if(this.tag_type==='brand_tag'){
                return this.is_edit ? 'Edit Content Tags' : 'Select or Create Content Tags';
            }else{
                return this.is_edit ? 'Edit Creator Tag' : 'Select a Creator Tag or create one';
            }
        },

        drop_style() {
            if (this.index !== null){
                if (this.index > 6){
                    if (this.index === 9999){
                        return `${this.drop_css? this.drop_css:'left: 160px'}`;
                    } else {
                        return `bottom:0px; ${this.drop_css? this.drop_css:'left: 200px'}; top:unset;`;
                    }
                
                } else {
                    return `top:0px; ${this.drop_css? this.drop_css:'left: 225px'};`;
                }
                
            } 
            
            else if (this.drop_css) {
                return this.drop_css;
            }
            
            else {
                return '';
            }
        },

    },

    methods: {
        handleClick(event) {
            event.stopPropagation();
            // console.log('内层组件被点击');
        },

        selectAllTag(){
           this.$emit('select-all')
        },

        selectthsTag(v){

            let orgSele=this.selected_tags.some((t) => t.id === v.id);//查看本來是true還是false,如果是true，要CALL刪除API
            
            //for creator tag(有些移除綁定會做在creatotTags.js,看移除綁定是在tag上面還是在這裡面)
            if(this.tag_type==='creator_tag'){
                this.is_updateing = true;
                this.is_loading=true;
                let rows = Array.isArray(this.row) ? this.row : [this.row];

                //如果他已經被選擇，代表現在要刪除
                let url = orgSele ? '/creator/unbind-user-tag' : '/creator/bind-user-tag';

                this.$ajax(url, {
                    method: 'post',
                    withCredentials: true,
                    data: { tag_id: v.id, anchor_creator_ids: rows.map(e => e.id) },
                }).then(res => {
                    this.$emit('select-tag', { tag: v, row: this.row });
                    this.is_updateing = false;
                    this.EventBus.emit('update-table-creator');
                }).catch(err => {
                    this.catchErr(err);
                });
            }
            //for brand tag
            else{
                this.$emit('select-tag', v)
            }
        },

        //will call api to save
        saveEdit(val){
            let v=this.now_edit_value;
            this.tags.forEach(e=>{
                if(e.id===v.id ){
                    e.is_edit=!val;
                    if(val){
                        e.text=v.temp;
                    }
                }
            })

            this.is_show_edit_modal = false;
            if(val){
                this.update('update',v) ;
                this.selected_tags.forEach(e=>{
                    if(e.id===v.id && val){
                        e.text=v.temp;
                    }
                });
            }
        },

        validateAddTag(tag,type) {
            let regex = /^[a-zA-Z0-9_-]+$/;
            let regexCreator = /^[a-zA-Z0-9.\-\s]+$/;
            let list = this.tags;
            let noNonEnglish = /^[\x00-\x7F]+$/;
            console.log('tag', tag)

            if (!this.new_tag.text) {
                this.is_error = true;
                this.error_text = 'Keyword is required';
                return;
            }

            if (this.tag_type==='brand_tag' && !regex.test(tag.text)) {
                this.is_error = true;
                this.error_text = `Entries may not include any spaces or special characters except '-' and '_'. `
                
                return;
            }

            if (this.tag_type==='brand_tag' && !noNonEnglish.test(tag.text)  ) {
                tag.error = true;
                tag.error_text = 'Entries may only include English characters.';
                return;
            }

            if (this.tag_type==='creator_tag' && !regexCreator.test(tag.text)) {
                this.is_error = true;
                this.error_text = `Creator tags must not contain special characters except ".", "-", and spaces.`
                
                return;
            }

            if (list.find(item => item.text.toLocaleLowerCase() === tag.text.toLocaleLowerCase())) {
                this.is_error = true;
                this.error_text = `This tag already exists.`;
                return;
            }
            else if (list.length >= this.max && this.tag_type==='brand_tag') { //只有brand tag 目前有限制
                this.is_error = true;
                this.error_text = 'Limit reached. Remove tags to add new ones.';
                return;
            } 
            else {
                tag.text = tag.text.replace(/[@#]/g, '');
                if(this.tag_type==='brand_tag'){
                    list.push(tag);
                }
                this.update(type,tag);

                this.is_error = false;
                this.new_tag = {
                    id: 0,
                    text: '',
                };
                this.list_key +=1;
                console.log('list',list)
            }
        },

        add(index) {
            this.validateAddTag(this.new_tag,'add');
        },

        update( type, tag ) {
            this.is_updateing = true;
            this.is_loading=true;
            if(this.tag_type==='creator_tag' ){
                if(this.is_show){
                    this.callCreatorUpdateApi(type,tag);
                }
            }else if(this.tag_type==='brand_tag'){
                this.callBrandUpdateApi(type);
            }
        },

        callBrandUpdateApi(type){
            const pushData=(arr)=>{
                let newarr=[];
                if(arr && arr.length>0){
                    arr.forEach(e=>{
                        newarr.push(e.text)
                    })
                }
                return newarr;
            }
            const hashtags = pushData(this.otherTag.hastags);
            const igMention=pushData(this.otherTag.instagram_mentions);
            const tkMention=pushData(this.otherTag.tiktok_mentions);
            const tags = pushData(this.tags);

            const data = {
                hashtags: hashtags,
                tags: tags,
                instagram_mentions:igMention,
                tiktok_mentions:tkMention,
            };

            this.$ajax('/creator/update-social-post-custom-filter', {
                method: 'post',
                withCredentials: true,
                data: data,
            }).then(res => {
                // this.$emit('update', data);
                const tags = res.data.data.social_post_tag_texts;
                // console.log(tags)
                this.EventBus.emit('updated-all-post-tag', tags);
                this.is_updateing = false;
                if(type ==='add'){
                    this.close()
                }
            }).catch(err => {
                this.catchErr(err);
            })
        },

        callCreatorUpdateApi( type, tag){
            
            switch (type) {
                case 'add':
                    this.$ajax('/creator/create-merchant-tag', {
                        method: 'post',
                        withCredentials: true,
                        data: { tag: tag.text },
                    }).then(res => {
                        tag.id=res.data.data;
                        tag.from_ambassador = 0;
                        this.tags.push(tag);
                        this.EventBus.emit('update-table-creator');
                        this.is_updateing = false;
                    }).catch(err => {
                        this.catchErr(err);
                    })
                    break;
                case 'update': 
                    this.$ajax('/creator/update-merchant-tag', {
                        method: 'post',
                        withCredentials: true,
                        data: { tag: tag.text , id: tag.id },
                    }).then(res => {
                        this.EventBus.emit('update-table-creator');
                        this.is_updateing = false;
                    }).catch(err => {   
                        this.catchErr(err);
                    })
                    break;
                case 'remove':
                    this.$ajax('/creator/delete-merchant-tag', {
                        method: 'post',
                        withCredentials: true,
                        data: { ids: tag.map(e=>{return e.id}) },
                    }).then(res => {
                        const idsInTag = new Set(tag.map(toRemove => toRemove.id));
                        const filteredTags = this.selected_tags.filter(item => !idsInTag.has(item.id));

                        // 使用 splice 來更新 selected_tags，而不是直接賦值
                        this.selected_tags.splice(0, this.selected_tags.length, ...filteredTags);
                        this.EventBus.emit('update-table-creator');
                        this.is_updateing = false;
                    }).catch(err => {
                        this.catchErr(err);
                    })
                    this.is_updateing = false;
                    break;

            }

        },

        catchErr(err){
            console.log(err)
            this.is_updateing = false;
            if (err && err.response && err.response.data.data) {
                this.is_error=true;
                this.error_text=err.response.data.data.message;
            }
        },

        remove(tagIndex, listIndex, socialInedex, index) {
            if (typeof index === 'number') {
                this.is_show_remove_modal = true;
                this.remove_item.tag = tagIndex;
                this.remove_item.list = listIndex;
                this.remove_item.social = socialInedex;
                this.remove_item.index = index;

            } else if (tagIndex === 'remove') {
                console.log('this.delete_tags',this.delete_tags)
                this.is_show_remove_modal = false;
                const idsInArray2 = new Set(this.delete_tags.map(item => item.id));
                const filteredArray = this.tags.filter(item => !idsInArray2.has(item.id));
                this.tags.splice(0, this.tags.length, ...filteredArray);
                console.log('re')
                this.update('remove',this.delete_tags);
   
            } else {
                this.is_show_remove_modal = false;
                this.remove_item.tag = 0;
                this.remove_item.list = 0;
                this.remove_item.social = 0;
                this.remove_item.index = 0;
            }
        },


        open(is_edit = false) {
            this.is_edit = is_edit;
            this.is_show = true;
            this.is_timer = true;

            setTimeout(() => {
                this.is_timer = false;
            }, 100);
        },

        close() {
            this.is_show = false;
            if(this.open_key){
                this.$emit('closeKey')
            }
        },

        selectDeleteTag(tag) {
            const index = this.delete_tags.findIndex((t) => t.id === tag.id);
            if (index === -1) {
                this.delete_tags.push(tag);
            } else {
                this.delete_tags.splice(index, 1);
            }
            this.$store.state.spec_value.del_tag_num=this.delete_tags.length;

        },

        handleClick(e) {
            if (this.$refs.el && !this.$refs.el.contains(e.target) && !this.is_timer && !this.spec_tag) {
                this.close();
            }
            // if (!this.$el.contains(e.target)) {
            //     console.log(2)
            //     this.$emit('close');
            // }
        },

        async callCreatorApi(ids){
            console.log('ids',ids)
            let str='?'; 
            ids.forEach(e=>{
                str+=`tag_ids[]=${e}&`;
            })
            str = str.slice(0, -1);  
            try {
                const res = await this.$ajax.get(`/creator/get-merchant-tag-used-count${str}`);
                this.$store.state.spec_value.del_tag_num = res.data.data;
            } catch (error) {
                console.error('Error fetching role list:', error);
            }
            
        },

        async handleDelBtn(){
            if(this.delete_tags.length === 0){
                return
            }else{
                let ids=this.delete_tags.map(e=>{return e.id});
                await this.callCreatorApi(ids)
                if(this.outside_remove){
                    this.$store.state.spec_value.open_tag_remove_outside_modal=true
                }else{
                    this.is_show_remove_modal = true;
                }
            }
        },

        async handleEditBtn(v){
            this.now_edit_value = v;
            let ids=[v]
            ids=ids.map(e=>{return e.id});
            await this.callCreatorApi(ids)
            if(this.outside_remove){
                this.$store.state.spec_value.open_tag_edit_outside_modal=true
            }else{
                this.s_show_edit_modal = true
            }
            
        },
    },

    created() {
        window.addEventListener('click', this.handleClick);
        // this.$eventBus.$emit('close-dropdown-tags', this);

        this.EventBus.on('close-dropdown-tags', () => {
            this.close();
        });
    },

    destroyed() {
        window.removeEventListener('click', this.handleClick);
    },
}
</script>
<style lang="scss">
.dropdown-hashtag {
    position: absolute;
    background-color: white;
    top: 100%;
    right: unset;//-20px;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    z-index: 9;
    width: 260px;
    display: none;

    .scroll-tag-box{
        max-height: 200px;
    }

    &.show {
        display: block;
    }

    .small-label {
        padding: 12px;
        border-bottom: 1px solid var(--black-20);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.label-center{
            border:none;
        }

        h3 {
            font-size: 12px;
            font-weight: 500;
            color: var(--black-75);
        }

        .svg-icon{
            width: 12px;
            height:12px;
            cursor:pointer;
        }
    }

    .input {
        position: relative;
        align-items: center;
        flex: 1;
        padding: 12px;
        border-bottom: 1px solid var(--black-20);

        &.input-center{
            border:none;
            margin: 24px 0;

            .alert{
                margin: 16px 0 0;
                color:var(--black-75);
                font-size: 12px;
            }
        }

        .input-box{
            position: relative;
            display: flex;
            align-items: center;
        }

        .error-text {
            color: var(--red) !important;
            font-size: 12px;
            white-space: break-spaces;
        }

        input {
            border-radius: 6px;
            display: block;
            color: var(--black-90);
            border: 1px solid var(--black-40);
            font-size: 12px;
            height: 26px;
            padding: 0 8px;
            width: 150px;

            &:focus {
                border: 1px solid var(--dark-blue);
            }
        }

        label {
            cursor: pointer;
            height: 26px;
            width: 42px;
            min-width: 42px;
            background: var(--dark-blue);
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            border-radius: 6px;
            margin-left: 8px;

            &.disabled {
                background: var(--black-20);
                cursor: not-allowed;
                pointer-events: none;

                svg {
                    fill: var(--black-40);
                }
            }
        }
    }

    .error-text {
        color: var(--red) !important;
        font-size: 12px;
    }

    ul {
        padding: 12px 4px 8px;

        li {
            margin: 4px 0 0;
            transition: .3s;

            &.check-li{
                padding: 0 8px; 
                cursor:pointer;
            }

            &.check-li:hover{
                background-color: var(--bg-02);
            }

            .check-li-box{
                display: flex;
                align-items: center;
                justify-content: space-between;

                input{
                    border: solid 1px var(--black-40);
                    padding: 4px;
                    border-radius: 4px;
                    width: calc(100% - 20px);
                }
                .pick-svg{
                    box-sizing: border-box;
                    padding: 6px;
                    min-width: 24px;
                    width: 24px ;
                    height: 24px ;
                    margin-left: 4px;

                    svg{
                        width:100%;
                        height:100%;
                    }

                    &.green svg{
                        fill:var(--green);
                        width:14px;
                    }

                    &:hover{
                        background-color: var(--bg-03);
                    }
                }
            }

            &.hide-check-li{
                cursor: not-allowed;
                .ui-checkbox{
                    cursor: not-allowed;
                    color:var(--black-40);

                    svg{
                        display: none;
                    }
                }
            }
        }
    }



    .ui-checkbox {
        margin: 0 !important;
        color: var(--black-90);
        display: flex;
        align-items: center;
        width: 100%;
        height: 24px;

        span{
            //text-ellipsis
            max-width: 140px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
        }

    }

    footer {
        padding: 12px;
        border-top: 1px solid var(--black-20);
        display: flex;

        .footer-ul {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: unset;
            li {
                padding-left: 8px;

                &.dis-li{
                    span{
                        background:var(--bg-02);
                        color:var(--gray-blue);
                    }

                    svg{
                        fill:var(--gray-blue);
                    }
                    
                }
            }
        }

        span {
            border: 1px solid var(--border);
            border-radius: 6px;
            height: 24px;
            padding: 0 6px;
            font-size: 12px;
            color: var(--black-75);
            cursor: pointer;
            display: inline-flex;
            align-items: center;

            &.cancel {
                border-color: white;
            }

            &.remove {
                color: var(--red);
            }

            svg {
                height: 12px;
                width: 12px;
                
                &.svg-icon--edit {
                    margin-right: 4px;
                }
                
                &.svg-icon--remove {
                    margin-left: 4px;
                }
            }
        }
    }

}

.alert-modal-indside{
    .ui-modal--container {
        padding:0 !important;
    }
}
</style>