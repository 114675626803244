const getTags = async (vi) => {
    try {
        const res = await vi.$ajax(`/creator/get-ambassador-tag-list?t=${new Date().getTime()}`, {
            method: 'get',
        });
        let data = [];
        res.data.data.forEach((item, index) => {
            data.push(
                {
                    ...item,
                    text: item['tag'],
                    is_selected: false,
                }
            )

        });

        return { orgData: res.data.data, data: data }

    } catch (error) {
        console.error("Error fetching ambassador tags:", error);
    }
};

//比對creator有什麼tag
const handleCreTag = (item, all_tag) => {

    item.merchant_account_tags.forEach(e => {
        e.id = e.tag_id;
        e.text = e.tag;
    })

    item.tags = JSON.parse(JSON.stringify(all_tag));
    item.tags.forEach((e, k) => {
        e.id= e.tag_id ? e.tag_id : e.id;
        e.text = e['tag']?e['tag']:e['text'];
        e.is_selected = item.merchant_account_tags.some(tag => {
            return tag.id === e.id;
        });
    });

};

const editOrDelCreTag = (value) => {
    console.log('value',value)
    switch (value.type) {
        case 'update':
            value.data.merchant_account_tags.forEach(e => {
                if (e.id === value.tag.id) {
                    e.text = value.tag.text;
                }
            })
            console.log('value',value.data)
            break;
        case 'remove':
            // 過濾掉那些ID匹配到的物件
            value.data.merchant_account_tags = value.data.merchant_account_tags.filter(tag =>
                !value.tag.some(toRemove => toRemove.id === tag.id)
            );
            console.log('value',value.data)
            break;

        case 'bind':
            const index = value.data.merchant_account_tags.findIndex((t) => t.id === value.tag.id);
            if (index === -1) {
                value.data.merchant_account_tags.push(value.tag);
            } else {
                value.data.merchant_account_tags.splice(index, 1);
            }
            console.log('value',value.data)
            break;

    };
};

const selectTag = (item, spec, vi) => {
    if(spec){
        console.log('item->',item)
    }

    const doThing = () => {
        const index = item.row.merchant_account_tags.findIndex((t) => t.id === item.tag.id);
        if (index === -1) {
            item.row.merchant_account_tags.push(item.tag);
        } else {
            item.row.merchant_account_tags.splice(index, 1);
        }
        vi.EventBus.emit('update-table-creator');
    }

    if (spec) {
        let rows = [item.row];
        vi.$store.state.is_loading = true;

        vi.$ajax('/creator/unbind-user-tag', {
            method: 'post',
            withCredentials: true,
            data: { tag_id: item.tag.id, anchor_creator_ids: rows.map(e => e.id) },
        }).then(res => {
            doThing();
            vi.$store.state.is_loading = false;
        }).catch(err => {
            vi.$store.state.is_loading = false;
        });
    } else {
        doThing();
    }

};


export {
    getTags,
    handleCreTag,
    selectTag,
    editOrDelCreTag,

}